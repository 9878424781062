import type { FC } from "react"
import { Text } from "@chakra-ui/react"

import type { Organization } from "query/graphql"
import CardContainer from "common/components/CardContainer"
import { getOrganizationDisclaimer } from "organization/constants/org-disclamer"

type Props = {
  organization: Organization
}
const OrganizationDisclaimer: FC<Props> = ({ organization }) => {
  const orgDisclaimer = organization
    ? getOrganizationDisclaimer(organization.id)
    : undefined

  if (!orgDisclaimer) return null

  if (!orgDisclaimer.homePage?.disclaimer) return null

  return (
    <CardContainer pt={{ base: 4, md: 0 }}>
      <Text color="gray.600" fontSize="xs">
        {orgDisclaimer.homePage.disclaimer}
      </Text>
    </CardContainer>
  )
}

export default OrganizationDisclaimer
