import type { FC } from "react"
import React, { useMemo } from "react"
import type { StackProps } from "@chakra-ui/react"
import {
  Box,
  Button,
  Flex,
  Text,
  Stack,
  Center,
  Skeleton,
  Link,
} from "@chakra-ui/react"
import { useAccount } from "wagmi"
import flatten from "lodash.flatten"

import LoadMoreButton from "common/components/LoadMoreButton"
import type {
  ChainId,
  GovernanceTallyProposalsQuery,
  GovernanceTallyProposalsQueryVariables,
  Proposal,
} from "query/graphql"
import {
  ProposalsSortBy,
  GovernanceTallyProposalsDocument,
} from "query/graphql"
import { ROUTES } from "common/constants/routes"
import GovernanceTallyProposalsTable from "governance/components/GovernanceTallyProposalsTable"
import { useInfiniteCursorQuery } from "query/hooks/useInfiniteQuery"
import { generateArrayOfNumbers } from "common/helpers/array"
import { useModals } from "common/hooks/useModals"
import CardContainer from "common/components/CardContainer"
import { getWhiteLabelRoute } from "whitelabel/utils/breadcrumb"
import { useMe } from "user/providers/MeProvider"
import { isSameAddress } from "web3/helpers/address"
import {
  FeatureFlag,
  useOrganization,
} from "organization/providers/OrganizationProvider"

type Props = {
  slug: string
  chainId: ChainId
  displayTop?: boolean
  isWhiteLabel: boolean
  displayEmptyMessage?: boolean
  organizationId: string
}

const GovernanceTallyProposalsList: FC<Props & StackProps> = ({
  slug,
  chainId,
  displayTop = true,
  displayEmptyMessage = false,
  isWhiteLabel,
  organizationId,
  ...stackProps
}) => {
  const { address: walletAddress } = useAccount()
  const me = useMe()
  const { walletSelection } = useModals()

  const { isFeatureFlagOn } = useOrganization()
  const isLegacyLayoutEnabled = isFeatureFlagOn(FeatureFlag.LegacyLayout)

  const isMeProfile = me?.address && isSameAddress(me.address, walletAddress)

  const voterAddress = !isMeProfile ? me?.address : walletAddress

  const proposerId = useMemo(() => {
    if (!walletAddress || !chainId || !voterAddress) return ""

    return voterAddress
  }, [walletAddress, chainId, voterAddress])

  const PAGE_SIZE = displayTop ? 4 : 10

  const isQueryEnabled = proposerId.length > 0
  const shouldDisplayConnectWallet = proposerId.length === 0

  const { data, isLoading, fetchNextPage, hasNextPage } =
    useInfiniteCursorQuery<
      GovernanceTallyProposalsQuery,
      GovernanceTallyProposalsQueryVariables
    >({
      document: GovernanceTallyProposalsDocument,
      sectionName: "GovernanceTallyProposals",
      nextPagePath: "proposals",
      variables: {
        input: {
          filters: {
            organizationId,
            proposer: proposerId,
            isDraft: true,
          },
          sort: {
            sortBy: ProposalsSortBy.Id,
            isDescending: true,
          },
          page: { limit: PAGE_SIZE },
        },
      },
      enabled: isQueryEnabled,
    })

  const isLoadMoreDisabled = !hasNextPage

  if (!data) {
    return null
  }

  const { pages } = data

  const allProposals = pages.map(
    ({ proposals }) => proposals.nodes as Proposal[],
  )

  const proposals = flatten(allProposals.map((thing) => thing))

  const handleLoadMoreClick = () => fetchNextPage()

  const handleConnectWalletClick = (): void => {
    walletSelection.onOpen()
  }

  if (!isQueryEnabled) return null

  if (isLoading) {
    return (
      <Stack
        as="section"
        mb={6}
        mt={isLegacyLayoutEnabled ? 2 : 0}
        spacing={6}
        {...stackProps}
      >
        <Flex border="gray.light" direction="column" rounded="sm" w="full">
          <Flex borderBottom="gray.light" h="2.668125rem" w="full">
            <Skeleton h="full" w="full" />
          </Flex>
          {generateArrayOfNumbers(4).map((number) => (
            <Flex
              key={`recent_vote_${number}`}
              borderBottom="gray.light"
              h="4.856875rem"
              w="full"
            >
              <Skeleton h="full" w="full" />
            </Flex>
          ))}
          <Flex h={12} w="full">
            <Skeleton h="full" w="full" />
          </Flex>
        </Flex>
      </Stack>
    )
  }

  const hasTallyProposals = proposals && proposals.length > 0

  if (!hasTallyProposals) {
    if (!displayEmptyMessage) {
      return null
    }

    return (
      <CardContainer
        isTableView
        mt={isLegacyLayoutEnabled ? 2 : 0}
        title={isLegacyLayoutEnabled ? "My Drafts" : undefined}
      >
        <Flex align="center" bg="gray.50" h={16} justify="center" mt={2} mx={4}>
          <Text color="gray.600" textStyle="body.bold.md">
            No drafts created yet
          </Text>
        </Flex>
      </CardContainer>
    )
  }

  return (
    <CardContainer
      isTableView
      mt={isLegacyLayoutEnabled ? 2 : 0}
      title={isLegacyLayoutEnabled ? "My Drafts" : undefined}
    >
      <Stack as="section" spacing={6} {...stackProps}>
        {shouldDisplayConnectWallet && (
          <>
            <Stack spacing={3}>
              <Text>To see your draft proposals, connect your wallet</Text>
              <Box>
                <Button variant="outline" onClick={handleConnectWalletClick}>
                  Connect wallet
                </Button>
              </Box>
            </Stack>
          </>
        )}
        {proposals && (
          <>
            <GovernanceTallyProposalsTable
              slug={slug}
              tallyProposals={proposals}
            />

            {displayTop ? (
              <Center>
                <Link
                  className="no-underline"
                  href={
                    isWhiteLabel
                      ? getWhiteLabelRoute(ROUTES.governance.drafts.list(slug))
                      : ROUTES.governance.drafts.list(slug)
                  }
                >
                  <Button
                    data-qa="governancetallyproposals-viewall-btn"
                    size="sm"
                    variant="tertiary"
                  >
                    View all
                  </Button>
                </Link>
              </Center>
            ) : null}

            {!displayTop && hasTallyProposals && !isLoadMoreDisabled ? (
              <Center mt={1} mx={2}>
                <LoadMoreButton
                  isDisabled={isLoadMoreDisabled}
                  width="100%"
                  onClick={handleLoadMoreClick}
                />
              </Center>
            ) : null}
          </>
        )}
      </Stack>
    </CardContainer>
  )
}

export default GovernanceTallyProposalsList
