import type { FC } from "react"
import React from "react"
import { Flex, Text, Box, Stack } from "@chakra-ui/react"

import Link from "common/components/Link"
import { ROUTES } from "common/constants/routes"
import type { Proposal } from "query/graphql"
import { getProposalIdLabel } from "proposal/helpers/id"
import TallyProposalStatusTag from "tallyProposal/components/TallyProposalStatusTag"

type TallyProposalIdentityProps = {
  slug: string
  tallyProposal: Proposal
}

const TallyProposalIdentity: FC<TallyProposalIdentityProps> = ({
  slug,
  tallyProposal,
}) => {
  if (!tallyProposal) {
    return null
  }

  const proposalLabelId = getProposalIdLabel(
    tallyProposal?.originalId ?? tallyProposal.id,
  )

  return (
    <Stack isInline align="center" spacing={4}>
      <Flex direction="column" maxW="lg">
        <Link
          href={ROUTES.governance.draft.index(
            slug,
            tallyProposal.originalId ?? tallyProposal.id,
          )}
        >
          <Text fontWeight="bold" mb={1} noOfLines={1} textStyle="md">
            {tallyProposal.metadata?.title}
          </Text>
        </Link>
        <Stack isInline align="center" spacing={1}>
          <TallyProposalStatusTag tallyProposal={tallyProposal} />
          <Box bg="gray.400" h={0.5} rounded="full" w={0.5} />
          <Text color="gray.400" textStyle="body.regular.sm">
            {`ID: ${proposalLabelId}`}
          </Text>
        </Stack>
      </Flex>
    </Stack>
  )
}

export default TallyProposalIdentity
