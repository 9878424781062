import type { FC, ReactNode } from "react"
import { HStack, Icon, Text } from "@chakra-ui/react"

import CardContainer from "common/components/CardContainer"
import Link from "common/components/Link"
import ChevronRight from "ui/components/icons/ChevronRight"
import CircleMark from "ui/components/icons/CircleMark"

type Props = {
  title: string
  footer?: string
  children: ReactNode
  isHighlight?: boolean
  url?: string
  h?: string | number
}
const MobileCountBox: FC<Props> = ({
  title,
  children,
  isHighlight,
  url,
  footer,
  h = "100px",
}) => {
  return (
    <Link className="no-underline" href={url ?? ""} width="100%">
      <CardContainer
        isTableView
        h={h}
        pt={{ base: 3, sm: 2 }}
        px={{ base: 2, sm: 4 }}
      >
        <HStack alignItems="center" justifyContent="space-between" mx={0}>
          <HStack spacing={1}>
            <Text align="center" as="h6" color="gray.800" fontSize="md">
              {title}
            </Text>
            {isHighlight ? (
              <Icon as={CircleMark} color="purple.500" h={3} w={3} />
            ) : null}
          </HStack>

          <Icon as={ChevronRight} color="gray.600" h={3} w={3} />
        </HStack>
        <Text
          as="h4"
          color="gray.800"
          fontSize={{ base: "24px", md: "26px" }}
          fontWeight="bold"
        >
          {children}
        </Text>
        {footer ? (
          <Text color="gray.500" fontSize="sm">
            {footer}
          </Text>
        ) : null}
      </CardContainer>
    </Link>
  )
}

export default MobileCountBox
