export type DisclaimerModalContent = {
  title: string
  body: string
  isMemberCheckboxLabel: string
}

export type OrganizationDisclaimerOptions = {
  vote: {
    content: DisclaimerModalContent
  }
  homePage?: {
    disclaimer: string
  }
  delegate?: {
    isSeekingDelegation: {
      on: string
      off: string
    }
  }
  createProposal?: {
    content: DisclaimerModalContent
  }
}

// Hardcoded list of Organizations and the disclaimer info
const ORG_DISCLAMER: Record<
  string,
  Record<string, OrganizationDisclaimerOptions>
> = {
  "https://api.tally.xyz": {
    // Codename-testnet (zkSync): https://www.tally.xyz/gov/codename-testnet
    "2333649329245390000": {
      vote: {
        content: {
          title: "Join the ZKsync Association",
          body: `By voting on ZKsync governance proposals through this site you agree to join the ZKsync Association, which offers personal liability protection. [Learn more](https://docs.zknation.io/legal/zksync-association-membership) about what membership means as a Delegate`,
          isMemberCheckboxLabel: `I do not want to be part of the ZKsync Association`,
        },
      },
      delegate: {
        isSeekingDelegation: {
          on: `I agree to join the ZKsync Association, which offers personal liability protection. [**Learn more**](https://docs.zknation.io/legal/zksync-association-membership) about what membership means as a Delegate`,
          off: `I confirm I am no longer part of the ZKsync Association. [Learn more](https://docs.zknation.io/legal/zksync-association-membership) about what membership means as a Delegate`,
        },
      },
      homePage: {
        disclaimer: `The ZKsync Association provides this portal to its members to facilitate and support the voting process and participation in ZKsync Governance. For a limited period, the portal will also be available for non-members to participate in the voting process to ensure the ongoing adoption of ZKsync. As the portal and its full functionalities are only intended for use by Delegates who are members of the ZKsync Association, once additional voting platforms become available, proposal submission and voting will require Delegates to form part of the ZKsync Association which offers personal liability protection.`,
      },
      createProposal: {
        content: {
          title: "Join the ZKsync Association",
          body: `By submitting a proposal through this site you agree to join the ZKsync Association, which offers personal liability protection. [Learn more](https://docs.zknation.io/legal/zksync-association-membership) about what membership means as a Delegate`,
          isMemberCheckboxLabel: `I do not want to be part of the ZKsync Association`,
        },
      },
    },
    // zkSync: https://www.tally.xyz/gov/zksync
    "2297436623035434412": {
      vote: {
        content: {
          title: "Join the ZKsync Association",
          body: `By voting on ZKsync governance proposals through this site you agree to join the ZKsync Association, which offers personal liability protection. [Learn more](https://docs.zknation.io/legal/zksync-association-membership) about what membership means as a Delegate`,
          isMemberCheckboxLabel: `I do not want to be part of the ZKsync Association`,
        },
      },
      homePage: {
        disclaimer: `The ZKsync Association provides this portal to its members to facilitate and support the voting process and participation in ZKsync Governance. For a limited period, the portal will also be available for non-members to participate in the voting process to ensure the ongoing adoption of ZKsync. As the portal and its full functionalities are only intended for use by Delegates who are members of the ZKsync Association, once additional voting platforms become available, proposal submission and voting will require Delegates to form part of the ZKsync Association which offers personal liability protection.`,
      },
      delegate: {
        isSeekingDelegation: {
          on: `I agree to join the ZKsync Association, which offers personal liability protection. [**Learn more**](https://docs.zknation.io/legal/zksync-association-membership) about what membership means as a Delegate`,
          off: `I confirm I am no longer part of the ZKsync Association. [Learn more](https://docs.zknation.io/legal/zksync-association-membership) about what membership means as a Delegate`,
        },
      },
      createProposal: {
        content: {
          title: "Join the ZKsync Association",
          body: `By submitting a proposal through this site you agree to join the ZKsync Association, which offers personal liability protection. [Learn more](https://docs.zknation.io/legal/zksync-association-membership) about what membership means as a Delegate`,
          isMemberCheckboxLabel: `I do not want to be part of the ZKsync Association`,
        },
      },
    },
    // testnet-zksync: https://www.tally.xyz/gov/testnet-zksync
    "2383575338992010920": {
      vote: {
        content: {
          title: "Join the ZKsync Association",
          body: `By voting on ZKsync governance proposals through this site you agree to join the ZKsync Association, which offers personal liability protection. [Learn more](https://docs.zknation.io/legal/zksync-association-membership) about what membership means as a Delegate`,
          isMemberCheckboxLabel: `I do not want to be part of the ZKsync Association`,
        },
      },
      delegate: {
        isSeekingDelegation: {
          on: `I agree to join the ZKsync Association, which offers personal liability protection. [**Learn more**](https://docs.zknation.io/legal/zksync-association-membership) about what membership means as a Delegate`,
          off: `I confirm I am no longer part of the ZKsync Association. [Learn more](https://docs.zknation.io/legal/zksync-association-membership) about what membership means as a Delegate`,
        },
      },
      homePage: {
        disclaimer: `The ZKsync Association provides this portal to its members to facilitate and support the voting process and participation in ZKsync Governance. For a limited period, the portal will also be available for non-members to participate in the voting process to ensure the ongoing adoption of ZKsync. As the portal and its full functionalities are only intended for use by Delegates who are members of the ZKsync Association, once additional voting platforms become available, proposal submission and voting will require Delegates to form part of the ZKsync Association which offers personal liability protection.`,
      },
      createProposal: {
        content: {
          title: "Join the ZKsync Association",
          body: `By submitting a proposal through this site you agree to join the ZKsync Association, which offers personal liability protection. [Learn more](https://docs.zknation.io/legal/zksync-association-membership) about what membership means as a Delegate`,
          isMemberCheckboxLabel: `I do not want to be part of the ZKsync Association`,
        },
      },
    },
  },
  "https://api.staging.tally.xyz": {},
}

export const getOrganizationDisclaimer = (organizationId: string) => {
  return (
    ORG_DISCLAMER?.[process.env.NEXT_PUBLIC_TALLY_API_URL]?.[organizationId] ??
    undefined
  )
}
