import { useEffect, useMemo, type FC } from "react"
import {
  Box,
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { Cookies, getCookieConsentValue } from "react-cookie-consent"
import { useAccount } from "wagmi"

import type { OrangeBannerButton } from "common/components/banners/OrangeBanner"
import OrangeBanner from "common/components/banners/OrangeBanner"
import {
  FeatureFlag,
  useOrganization,
} from "organization/providers/OrganizationProvider"
import { ROUTES } from "common/constants/routes"
import {
  DelegatesSortBy,
  useDelegateStatementQuery,
  type Organization,
} from "query/graphql"
import { useRouter } from "common/hooks/useRouter"
import { useMe } from "user/providers/MeProvider"

const modalIdCookie = "ArbRelegationWeekModal"

const DelegationWeekBanner: FC = () => {
  const { isFeatureFlagOn, organization } = useOrganization()
  const redelegationModalDisclousure = useDisclosure()

  const { address: walletAddress } = useAccount()
  const me = useMe()
  const address = me?.type === "SAFE" ? me.address : walletAddress

  const isPreDelegationWeekEnabled = isFeatureFlagOn(
    FeatureFlag.PreDelegationWeek,
  )
  const isDelegationWeekEnabled = isFeatureFlagOn(FeatureFlag.DelegationWeek)

  useEffect(() => {
    if (isDelegationWeekEnabled && !getCookieConsentValue(modalIdCookie)) {
      redelegationModalDisclousure.onOpen()

      return
    }
  }, [redelegationModalDisclousure, isDelegationWeekEnabled])

  const { data: delegateStatementData } = useDelegateStatementQuery(
    {
      input: {
        address: address ?? "",
        organizationId: organization?.id ?? "",
      },
    },
    {
      enabled: Boolean(address) && Boolean(organization),
    },
  )

  const buttons = useMemo(() => {
    if (!organization) {
      return []
    }

    if (isPreDelegationWeekEnabled) {
      return [
        {
          label: "Learn more",
          url: "https://forum.arbitrum.foundation/t/what-is-re-delegation-week-application-thread/25262",
          isExternal: true,
          isPrimary: true,
        },
      ]
    }

    // User didn't connect wallet yet
    if (!address) {
      return [
        {
          label: "Review my voting power",
          url: ROUTES.governance.myVotingPower.index(organization.slug),
          isPrimary: true,
        },
      ]
    }

    const hasDelegateProfile = Boolean(delegateStatementData?.delegate)

    // User connected wallet and already has a delegate profile
    if (hasDelegateProfile) {
      return [
        {
          label: "View delegate profile",
          url: ROUTES.governance.delegate.profile(organization.slug, address),
        },
        {
          label: "Review my voting power",
          url: ROUTES.governance.myVotingPower.index(organization.slug),
          isPrimary: true,
        },
      ]
    }

    // User connected wallet and has not a delegate profile
    return [
      {
        label: "Review my voting power",
        url: ROUTES.governance.myVotingPower.index(organization.slug),
      },
      {
        label: "Create delegate profile",
        url: ROUTES.governance.delegate.register(organization.slug),
        isPrimary: true,
      },
    ]
  }, [address, delegateStatementData, isPreDelegationWeekEnabled, organization])

  if (!isDelegationWeekEnabled && !isPreDelegationWeekEnabled) return null

  if (!organization) return null

  return (
    <>
      <OrangeBanner
        bgImageUrl="https://static.tally.xyz/2862cd0b-12eb-478c-af69-850078485d38_original.png"
        buttons={buttons as unknown as OrangeBannerButton[]}
        description="July 17-23"
        title="Arb Re-delegation week"
      />
      {isDelegationWeekEnabled ? (
        <RedelegationWeekModal
          isOpen={redelegationModalDisclousure.isOpen}
          organization={organization}
          onClose={redelegationModalDisclousure.onClose}
        />
      ) : null}
    </>
  )
}

export default DelegationWeekBanner

type RedelegationWeekModalProps = {
  isOpen: boolean
  organization: Organization
  onClose: () => void
}

const RedelegationWeekModal: FC<RedelegationWeekModalProps> = ({
  isOpen,
  onClose,
  organization,
}) => {
  const { push } = useRouter()

  const handleCloseClick = () => {
    Cookies.set(modalIdCookie, true)
  }

  const handleReviewNowClick = () => {
    Cookies.set(modalIdCookie, true)
    push(ROUTES.governance.myVotingPower.index(organization.slug))
  }

  const handleExploreDelegatesClick = () => {
    Cookies.set(modalIdCookie, true)
    push(
      ROUTES.governance.delegates(
        organization.slug,
        DelegatesSortBy.Prioritized,
      ),
    )
  }

  const bgImageUrl =
    "https://static.tally.xyz/0d949e91-fcda-46c5-a3e9-6ebed6fb0c51_original.png"

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        minW={{ base: "auto", lg: "30rem" }}
        my={{ base: "auto", lg: "1rem" }}
      >
        <Box borderTopRadius="lg" overflow="hidden">
          <ModalHeader
            backgroundImage={`url(${bgImageUrl})`}
            backgroundPosition="center"
            backgroundSize="cover"
            bg={bgImageUrl}
            borderBottom="gray.light"
          >
            <Stack minHeight="100px">
              <ModalCloseButton color="white" onClick={handleCloseClick} />
            </Stack>
          </ModalHeader>
          <ModalBody overflowY="auto" p={0}>
            <Stack
              align="center"
              direction="column"
              p={3}
              spacing={2}
              width="100%"
            >
              <Text as="h5" fontSize="2xl" fontWeight="700">
                Re-delegation Week is live!
              </Text>
              <Text align="center" color="gray.500" fontSize="sm">
                July 17-23
              </Text>
              <Text align="center" color="gray.600" fontSize="md">
                Reassess your votes and support a more active, decentralized
                Arbitrum DAO.
              </Text>
            </Stack>
            <Divider />
          </ModalBody>
          <ModalFooter p={3}>
            <Stack direction="column" spacing={2} width="100%">
              <Button
                py={4}
                variant="primary"
                width="100%"
                onClick={handleReviewNowClick}
              >
                Review my voting power
              </Button>
              <Button
                py={4}
                variant="secondary"
                width="100%"
                onClick={handleExploreDelegatesClick}
              >
                Explore delegates
              </Button>
            </Stack>
          </ModalFooter>
        </Box>
      </ModalContent>
    </Modal>
  )
}
