import { useRef, type FC } from "react"
import { Box, Skeleton } from "@chakra-ui/react"
import { useInView } from "framer-motion"

import {
  useOrganizationHomeProposalsMobileQuery,
  type Organization,
} from "query/graphql"
import MobileCountBox from "common/components/MobileCountBox"
import { ROUTES } from "common/constants/routes"
import { useDevice } from "common/hooks/useDevice"

type Props = {
  organization: Organization
}
const OrganizationHomeProposalsMobile: FC<Props> = ({ organization }) => {
  const ref = useRef(null)
  const isVisible = useInView(ref)
  const { onLittleDevice, onLargeDevice } = useDevice()
  const { data, isLoading } = useOrganizationHomeProposalsMobileQuery(
    {
      input: { slug: organization.slug },
    },
    { enabled: isVisible },
  )

  const value = isLoading ? (
    <Skeleton h="32px" mt={2} />
  ) : (
    data?.organization?.proposalsCount
  )

  const hasActiveProposals = data?.organization?.hasActiveProposals ?? false

  return (
    <Box ref={ref}>
      <Box display={onLargeDevice} h="110px">
        <MobileCountBox
          footer={
            Boolean(hasActiveProposals)
              ? "There are active proposals"
              : "No active proposals"
          }
          h="110px"
          title="Proposals"
          url={ROUTES.governance.proposals.list(organization.slug)}
        >
          {value}
        </MobileCountBox>
      </Box>
      <Box ref={ref} display={onLittleDevice} h="110px">
        <MobileCountBox
          footer={
            Boolean(hasActiveProposals)
              ? "Active proposals"
              : "No active proposals"
          }
          h="110px"
          title="Proposals"
          url={ROUTES.governance.proposals.list(organization.slug)}
        >
          {value}
        </MobileCountBox>
      </Box>
    </Box>
  )
}

export default OrganizationHomeProposalsMobile
