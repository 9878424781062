import { useRef, type FC } from "react"
import { Box, Skeleton } from "@chakra-ui/react"
import { useInView } from "framer-motion"

import {
  useOrganizationHomeDelegatesMobileQuery,
  type Organization,
} from "query/graphql"
import MobileCountBox from "common/components/MobileCountBox"
import { ROUTES } from "common/constants/routes"
import { pluralize } from "common/helpers/string"
import { labelNumber } from "common/helpers/number"
import { useDevice } from "common/hooks/useDevice"

type Props = {
  organization: Organization
}
const OrganizationHomeDelegatesMobile: FC<Props> = ({ organization }) => {
  const ref = useRef(null)
  const isVisible = useInView(ref)
  const { onLittleDevice, onLargeDevice } = useDevice()
  const { data, isLoading } = useOrganizationHomeDelegatesMobileQuery(
    {
      input: { slug: organization.slug },
    },
    { enabled: isVisible },
  )

  const value = isLoading ? (
    <Skeleton h="32px" mt={2} />
  ) : data?.organization?.delegatesCount !== undefined &&
    data?.organization?.delegatesCount !== null ? (
    labelNumber(data?.organization?.delegatesCount as number)
  ) : undefined

  const tokenHolders =
    data?.organization?.tokenOwnersCount !== undefined &&
    data?.organization?.tokenOwnersCount !== null
      ? data?.organization?.tokenOwnersCount
      : undefined

  return (
    <Box ref={ref}>
      <Box display={onLargeDevice} h="110px">
        <MobileCountBox
          footer={
            Boolean(tokenHolders)
              ? `${labelNumber(tokenHolders as number)} token ${pluralize(
                  tokenHolders as number,
                  "holder",
                  "holders",
                )}`
              : undefined
          }
          h="110px"
          title="Delegates"
          url={ROUTES.governance.delegates(organization.slug)}
        >
          {value}
        </MobileCountBox>
      </Box>
      <Box display={onLittleDevice} h="110px">
        <MobileCountBox
          footer={
            Boolean(tokenHolders)
              ? `${labelNumber(tokenHolders as number)} ${pluralize(
                  tokenHolders as number,
                  "holder",
                  "holders",
                )}`
              : undefined
          }
          h="110px"
          title="Delegates"
          url={ROUTES.governance.delegates(organization.slug)}
        >
          {value}
        </MobileCountBox>
      </Box>
    </Box>
  )
}

export default OrganizationHomeDelegatesMobile
