import type { FC } from "react"
import React from "react"
import type { StackProps } from "@chakra-ui/react"
import { Stack } from "@chakra-ui/react"

import type { ChainId, AccountId, Organization } from "query/graphql"
import GovernanceTallyProposalsList from "governance/components/GovernanceTallyProposalsList"
import GovernanceProposalsList from "governance/components/GovernanceProposalsList"

type Props = {
  slug: string
  chainId?: ChainId
  governorIds: AccountId[]
  organization: Organization
  isWhiteLabel: boolean
  displayGovernorColumn?: boolean
}

const GovernanceProposals: FC<Props & StackProps> = ({
  slug,
  chainId,
  governorIds,
  organization,
  isWhiteLabel,
  displayGovernorColumn = true,
  ...stackProps
}) => {
  return (
    <Stack as="section" spacing={1} {...stackProps}>
      {chainId ? (
        <GovernanceTallyProposalsList
          displayTop
          chainId={chainId}
          isWhiteLabel={isWhiteLabel}
          organizationId={organization.id}
          slug={slug}
        />
      ) : null}
      <GovernanceProposalsList
        displayTop
        chainId={chainId}
        displayCreateProposal={false}
        displayGovernorColumn={displayGovernorColumn}
        governorIds={governorIds}
        isWhiteLabel={isWhiteLabel}
        organization={organization}
        slug={slug}
      />
    </Stack>
  )
}

export default GovernanceProposals
