import type { FC, ReactNode } from "react"
import type { ComponentWithAs, IconProps } from "@chakra-ui/react"
import {
  Text,
  HStack,
  Link,
  Icon,
  Stack,
  Button,
  useDisclosure,
  Divider,
} from "@chakra-ui/react"

import type { Organization } from "query/graphql"
import CardContainer from "common/components/CardContainer"
import { ArrowUpRight } from "ui/components/icons/font-awesome/ArrowUpRight"
import DiscordIcon from "ui/components/icons/DiscordIcon"
import TwitterIcon from "ui/components/icons/TwitterIcon"
import TelegramIcon from "ui/components/icons/TelegramIcon"
import { LinkSimple } from "ui/components/icons/font-awesome/LinkSimple"
import DiscourseIcon from "ui/components/icons/DiscourseIcon"
import ContractsModal from "governance/components/ContractsModal"
import { Code } from "ui/components/icons/font-awesome/Code"
import { ChevronRight } from "ui/components/icons/font-awesome/solid/ChevronRight"
import type { ExternalLink } from "organization/helpers/fetcher"
import { EXTERNAL_ROUTES, ROUTES } from "common/constants/routes"
import { Bell } from "ui/components/icons/font-awesome/Bell"
import { Globe } from "ui/components/icons/font-awesome/Globe"
import { useIsWhiteLabel } from "whitelabel/hooks/useIsWhiteLabel"
import { getWhiteLabelRoute } from "whitelabel/utils/breadcrumb"
import { CircleQuestion } from "ui/components/icons/font-awesome/CircleQuestion"
import { getOrganizationFAQs } from "whitelabel/constants/faqs"
import { getOrganizationClaimHelpLinks } from "whitelabel/constants/helps"
import PageOwnerAndEditorModal from "organization/components/OrganizationPageOwnerAndEditorModal"
import { PeopleGroup } from "ui/components/icons/font-awesome/PeopleGroup"

type Props = {
  organization: Organization
  externalLinks?: ExternalLink[]
  displayTitle?: boolean
  displayOrgLinks?: boolean
}
const OrganizationHelpfulLinks: FC<Props> = ({
  organization,
  externalLinks,
  displayOrgLinks = true,
  displayTitle = true,
}) => {
  const { metadata } = organization
  const isWhiteLabel = useIsWhiteLabel()
  const contractsModalDisclosure = useDisclosure()
  const pageOwnerAndEditorModalDisclosure = useDisclosure()

  const socials = metadata?.socials || {}
  const { discord, others, telegram, twitter, website, discourse } = socials

  const notificationsLink =
    organization.governorIds?.length > 0
      ? EXTERNAL_ROUTES.notifications.dao(organization.id)
      : undefined

  const hasLinks = displayOrgLinks
    ? discord ||
      telegram ||
      twitter ||
      website ||
      discourse ||
      notificationsLink ||
      (others?.length ?? 0) > 0
    : false

  const hasFAQsLinks =
    getOrganizationFAQs(organization.id)?.length > 0 ||
    getOrganizationClaimHelpLinks(organization.id)?.length > 0

  if (!hasLinks && organization.governorIds?.length <= 0) {
    return null
  }

  return (
    <CardContainer
      isTableView
      pb={0}
      title={displayTitle ? "Helpful Links" : undefined}
    >
      <Stack
        divider={<Divider />}
        mt={0}
        pb={1}
        pt={displayTitle ? 1 : 2}
        spacing={1}
      >
        {externalLinks &&
          externalLinks?.length > 0 &&
          externalLinks?.map(({ label, url }, idx) => (
            <LinkContainer
              key={`ext-link-${idx}`}
              icon={LinkSimple}
              label={label}
              url={url}
            />
          ))}

        {organization.governorIds?.length > 0 ? (
          <LinkContainer
            icon={Code}
            label="Contracts and parameters"
            onClick={() => contractsModalDisclosure.onOpen()}
          />
        ) : null}

        {organization.governorIds?.length > 1 ? null : (
          <LinkContainer
            icon={PeopleGroup}
            label="Page owner and editors"
            onClick={() => pageOwnerAndEditorModalDisclosure.onOpen()}
          />
        )}

        {displayOrgLinks && website ? (
          <LinkContainer icon={Globe} label="Website" url={website} />
        ) : null}
        {displayOrgLinks && twitter ? (
          <LinkContainer icon={TwitterIcon} label="Twitter" url={twitter} />
        ) : null}
        {displayOrgLinks && discord ? (
          <LinkContainer icon={DiscordIcon} label="Discord" url={discord} />
        ) : null}
        {displayOrgLinks && telegram ? (
          <LinkContainer icon={TelegramIcon} label="Telegram" url={telegram} />
        ) : null}
        {displayOrgLinks && discourse ? (
          <LinkContainer
            icon={DiscourseIcon}
            label="Governance forum"
            url={discourse}
          />
        ) : null}
        {displayOrgLinks && others
          ? others.map((otherLink, idx) => {
              if (!otherLink?.label) {
                return null
              }

              return (
                <LinkContainer
                  key={`link-${idx}`}
                  icon={LinkSimple}
                  label={otherLink?.label}
                  url={otherLink.value}
                />
              )
            })
          : null}

        {notificationsLink ? (
          <LinkContainer
            icon={Bell}
            label="Notifications"
            url={notificationsLink}
          />
        ) : null}

        {hasFAQsLinks ? (
          <LinkContainer
            icon={CircleQuestion}
            label="FAQ"
            url={
              isWhiteLabel
                ? getWhiteLabelRoute(ROUTES.governance.faq(organization.slug))
                : ROUTES.governance.faq(organization.slug)
            }
          />
        ) : null}
      </Stack>

      {organization.governorIds?.length > 0 ? (
        <ContractsModal
          governanceIds={organization.governorIds}
          isOpen={contractsModalDisclosure.isOpen}
          onClose={contractsModalDisclosure.onClose}
        />
      ) : null}

      <PageOwnerAndEditorModal
        isOpen={pageOwnerAndEditorModalDisclosure.isOpen}
        organization={organization}
        onClose={pageOwnerAndEditorModalDisclosure.onClose}
      />
    </CardContainer>
  )
}

type LinkContainerProps = {
  label: string
  url?: string
  icon: ComponentWithAs<"svg", IconProps>
  onClick?: () => void
}
const LinkContainer: FC<LinkContainerProps> = ({
  url,
  label,
  icon,
  onClick,
}) => {
  const LinkWrapper = ({
    children,
    url,
  }: {
    children: ReactNode
    url: string
  }) => (
    <HStack
      isExternal
      _hover={{ bgColor: "gray.100" }}
      as={Link}
      borderRadius="md"
      className="no-underline"
      href={url}
      justify="space-between"
      mx={2}
      my={0.5}
      px={3}
      py={2}
    >
      {children}
    </HStack>
  )
  const ButtonWrapper = ({
    children,
    onClick,
  }: {
    children: ReactNode
    onClick: () => void
  }) => (
    <HStack
      _hover={{ bgColor: "gray.100" }}
      as={Button}
      borderRadius="md"
      className="no-underline"
      justify="space-between"
      mx={2}
      my={0.5}
      px={3}
      py={2}
      variant="transparent"
      onClick={onClick}
    >
      {children}
    </HStack>
  )

  if (url) {
    return (
      <LinkWrapper url={url}>
        <HStack>
          <Icon as={icon} color="gray.500" h={5} w={5} />
          <Text fontSize="md" fontWeight="medium">
            {label}
          </Text>
        </HStack>
        <Icon as={ArrowUpRight} color="gray.500" h={4} w={4} />
      </LinkWrapper>
    )
  }

  if (onClick) {
    return (
      <ButtonWrapper onClick={onClick}>
        <HStack>
          <Icon as={icon} color="gray.500" h={5} w={5} />
          <Text fontSize="md" fontWeight="medium">
            {label}
          </Text>
        </HStack>
        <Icon as={ChevronRight} h={3} w={3} />
      </ButtonWrapper>
    )
  }

  return null
}

export default OrganizationHelpfulLinks
