import React from "react"
import { createIcon } from "@chakra-ui/icons"

const FilledCircleCheck = createIcon({
  displayName: "GreenCircleCheck",
  viewBox: "0 0 16 16",
  path: (
    <path
      d="M15.75 8C15.75 3.75 12.25 0.25 8 0.25C3.71875 0.25 0.25 3.75 0.25 8C0.25 12.2812 3.71875 15.75 8 15.75C12.25 15.75 15.75 12.2812 15.75 8ZM7.09375 12.125C6.90625 12.3125 6.5625 12.3125 6.375 12.125L3.125 8.875C2.9375 8.6875 2.9375 8.34375 3.125 8.15625L3.84375 7.46875C4.03125 7.25 4.34375 7.25 4.53125 7.46875L6.75 9.65625L11.4375 4.96875C11.625 4.75 11.9375 4.75 12.125 4.96875L12.8438 5.65625C13.0312 5.84375 13.0312 6.1875 12.8438 6.375L7.09375 12.125Z"
      fill="currentColor"
    />
  ),
})

export default FilledCircleCheck
