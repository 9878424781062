import type { FC } from "react"
import React from "react"
import type { ButtonProps } from "@chakra-ui/button"
import { Button } from "@chakra-ui/button"

type Props = {
  isDisabled?: boolean
  label?: string
  onClick: () => void
}

const LoadMoreButton: FC<Props & ButtonProps> = ({
  onClick,
  isDisabled = false,
  label = "Load more",
  ...buttonProps
}) => {
  return (
    <Button
      isDisabled={isDisabled}
      size="md"
      variant="tertiary"
      w={28}
      onClick={onClick}
      {...buttonProps}
    >
      {label}
    </Button>
  )
}

export default LoadMoreButton
