import type { ButtonProps, StackProps } from "@chakra-ui/react"
import {
  Box,
  Button,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import Link from "next/link"
import { type FC, type ReactNode } from "react"
import { ChevronDownIcon, InfoIcon } from "@chakra-ui/icons"

import { useRouter } from "common/hooks/useRouter"
import { useDevice } from "common/hooks/useDevice"

type NavigationTab = {
  label: string
  url: string
  isActive?: boolean
  isDisabled?: boolean
  name?: string
}

type Props = {
  tabs: NavigationTab[]
  children?: ReactNode
  topLeft?: ReactNode
  onOpenInfoModal?: () => void
}

const TitleNavigationTabs: FC<Props & StackProps> = ({
  tabs,
  children,
  topLeft,
  onOpenInfoModal,
  ...stackProps
}) => {
  const { onLargeDevice, onLittleDevice } = useDevice()

  const router = useRouter()

  const tabIndex = tabs.findIndex((tab) => tab.isActive === true)

  if (tabIndex < 0) return null

  return (
    <>
      <Stack
        background="white"
        borderWidth={1}
        display={onLargeDevice}
        spacing={1}
        zIndex={10}
        {...stackProps}
      >
        <Box mb="-1px" scrollBehavior="unset" width="100%">
          <Tabs colorScheme="purple" index={tabIndex} pt={4}>
            <Flex justify="space-between" w="full">
              {topLeft ? topLeft : null}

              <TabList mr={2}>
                {tabs.map((tab) => (
                  <Tooltip
                    key={`tooltip-${tab.url}`}
                    hasArrow
                    label={tab.name}
                    placement="top"
                  >
                    <Tab
                      _focus={{ boxShadow: "none", border: "none" }}
                      _selected={{
                        color: "purple.500",
                        fontWeight: "bold",
                        fontStyle: "md",
                        borderBottomColor: "purple.500",
                      }}
                      as={Link}
                      borderBottomWidth="3px"
                      fontSize="md"
                      href={tab.url}
                      isDisabled={tab.isDisabled}
                      mt="-4px"
                      pt="-1px"
                    >
                      <Text>{tab.label}</Text>
                    </Tab>
                  </Tooltip>
                ))}
              </TabList>
            </Flex>
          </Tabs>
        </Box>

        {children}
      </Stack>

      <Box display={onLittleDevice}>
        <Menu>
          <Flex alignItems="center" justifyContent="space-between" width="full">
            <MenuButton
              _active={{ bgColor: "white" }}
              _empty={{ bgColor: "white" }}
              _focus={{ bgColor: "white" }}
              as={Button}
              bgColor="white"
              borderWidth="1px"
              height="50px"
              m={0}
              rightIcon={<ChevronDownIcon />}
            >
              <NavigationOption pl={0}>{tabs[tabIndex].label}</NavigationOption>
            </MenuButton>

            <Button
              backgroundColor="white"
              border="1px"
              borderColor="gray.200"
              borderRadius="lg"
              height="50px"
              ml={2}
              onClick={(e) => {
                e.preventDefault()
                if (onOpenInfoModal) {
                  onOpenInfoModal()
                }
              }}
            >
              <HStack spacing={2}>
                <InfoIcon />
              </HStack>
            </Button>
          </Flex>
          <MenuList mt={-2} mx={4} rootProps={{ width: "100%" }} zIndex={500}>
            {tabs.map((tab) => {
              return (
                <MenuItem
                  key={tab.label}
                  bgColor="white"
                  height="50px"
                  isDisabled={tabs[tabIndex].url === tab.url || tab.isDisabled}
                >
                  <NavigationOption
                    onClick={() => {
                      router.push(tab.url)
                    }}
                  >
                    {tab.name ? tab.name : tab.label}
                  </NavigationOption>
                </MenuItem>
              )
            })}
          </MenuList>
        </Menu>
      </Box>
    </>
  )
}

export default TitleNavigationTabs

type NavigationOptionProps = {
  children: ReactNode
  onClick?: () => void
}
const NavigationOption: FC<NavigationOptionProps & ButtonProps> = ({
  children,
  onClick,
  ...buttonProps
}) => {
  return (
    <Box as="li" listStyleType="none" role="none" width="100%">
      <Button
        bg="white"
        h="full"
        rounded="md"
        w="full"
        onClick={onClick}
        {...buttonProps}
      >
        <Stack align="center" direction="row" justify="flex-start" w="full">
          <Text
            color="neutral.700"
            fontSize="16px"
            fontWeight="medium"
            textAlign="left"
            width="100%"
          >
            {children}
          </Text>
        </Stack>
      </Button>
    </Box>
  )
}
