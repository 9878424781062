import { useMediaQuery as useMediaQueryChakra } from "@chakra-ui/react"

type Values = {
  isMobile: boolean
  isDesktop: boolean
}

export const useMediaQuery = (): Values => {
  const [isSmallerThan768] = useMediaQueryChakra("(max-width: 768px)")

  return {
    isMobile: isSmallerThan768,
    isDesktop: !isSmallerThan768,
  }
}
