import { useMemo, type FC } from "react"
import { Badge, Button, Flex, HStack, Stack, Text } from "@chakra-ui/react"

import CardContainer from "common/components/CardContainer"
import MarkdownViewer from "common/components/MarkdownViewer"
import Link from "common/components/Link"
import { ROUTES } from "common/constants/routes"
import type { Organization } from "query/graphql"
import { OrganizationStatus } from "organization/helpers/fetcher"
import { useMediaQuery } from "common/hooks/useMediaQuery"
import {
  FeatureFlag,
  useOrganization,
} from "organization/providers/OrganizationProvider"

type OrganizationDelegationPortalProps = {
  organization: Organization
  content: string
  status: OrganizationStatus
}

const OrganizationDelegationPortal: FC<OrganizationDelegationPortalProps> = ({
  organization,
  content,
  status,
}) => {
  const { isMobile } = useMediaQuery()
  const { isFeatureFlagOn } = useOrganization()

  const title = useMemo(() => {
    if (isMobile) {
      return (
        <HStack>
          <Text>
            {isFeatureFlagOn(FeatureFlag.UseWormholeTokenBridgeDelegation)
              ? "Staking"
              : "Delegation"}{" "}
            portal
          </Text>
          {status === OrganizationStatus.CallForDelegates ? (
            <Badge>Soon</Badge>
          ) : (
            <Badge colorScheme="purple">Available Now</Badge>
          )}
        </HStack>
      )
    }

    return isFeatureFlagOn(FeatureFlag.UseWormholeTokenBridgeDelegation)
      ? "Staking portal"
      : "Delegation portal"
  }, [isMobile, status, isFeatureFlagOn])

  const delegateRoute = isFeatureFlagOn(
    FeatureFlag.UseWormholeTokenBridgeDelegation,
  )
    ? ROUTES.governance.delegate.select(organization.slug)
    : ROUTES.governance.delegates(organization.slug)

  return (
    <CardContainer
      displayCollapseButton={isMobile}
      elementTitle={title}
      isCollapsed={isMobile && status === OrganizationStatus.CallForDelegates}
      mt={0}
    >
      <Stack pt={{ base: 2, lg: 0 }} spacing={4}>
        <MarkdownViewer content={content} />

        {status === OrganizationStatus.CallForDelegates ? (
          <Flex>
            <Button
              isDisabled
              fontSize="md"
              variant="primary"
              width={{ base: "100%", md: "auto" }}
            >
              Coming soon
            </Button>
          </Flex>
        ) : null}

        {status === OrganizationStatus.ClaimLive ? (
          <Link href={delegateRoute} width="100%">
            <Button
              fontSize="md"
              variant="primary"
              width={{ base: "100%", md: "auto" }}
            >
              {isFeatureFlagOn(FeatureFlag.UseWormholeTokenBridgeDelegation)
                ? "Stake for governance"
                : "Delegate"}
            </Button>
          </Link>
        ) : null}
      </Stack>
    </CardContainer>
  )
}

export default OrganizationDelegationPortal
