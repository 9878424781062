import React from "react"
import { createIcon } from "@chakra-ui/icons"

const DiscourseIcon = createIcon({
  displayName: "DiscourseIcon",
  viewBox: "0 0 448 512",
  path: (
    <path
      d="M225.9 32C103.3 32 0 130.5 0 252.1 0 256 .1 480 .1 480l225.8-.2c122.7 0 222.1-102.3 222.1-223.9C448 134.3 348.6 32 225.9 32zM224 384c-19.4 0-37.9-4.3-54.4-12.1L88.5 392l22.9-75c-9.8-18.1-15.4-38.9-15.4-61 0-70.7 57.3-128 128-128s128 57.3 128 128-57.3 128-128 128z"
      fill="currentColor"
    />
  ),
})

export default DiscourseIcon
