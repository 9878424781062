import { useMemo, type FC } from "react"
import { Badge, Box, Button, HStack, Stack, Text } from "@chakra-ui/react"
import { useAccount } from "wagmi"

import CardContainer from "common/components/CardContainer"
import MarkdownViewer from "common/components/MarkdownViewer"
import Link from "common/components/Link"
import { ROUTES } from "common/constants/routes"
import { useDelegateStatementQuery, type Organization } from "query/graphql"
import { useMediaQuery } from "common/hooks/useMediaQuery"

type OrganizationRegisterAsADelegateProps = {
  organization: Organization
  content: string
}

const OrganizationRegisterAsADelegate: FC<
  OrganizationRegisterAsADelegateProps
> = ({ organization, content }) => {
  const { address } = useAccount()

  const { isMobile } = useMediaQuery()

  const { data, isFetching } = useDelegateStatementQuery(
    {
      input: {
        address: address ?? "",
        organizationId: organization.id,
      },
    },
    { enabled: Boolean(address) },
  )

  const hasDelegateStatement = address && Boolean(data?.delegate?.statement)

  const title = useMemo(() => {
    if (isMobile) {
      return (
        <HStack>
          <Text>Register as a delegate</Text>
          <Badge colorScheme="purple">Available</Badge>
        </HStack>
      )
    }

    return "Register as a delegate"
  }, [isMobile])

  return (
    <CardContainer displayCollapseButton={isMobile} elementTitle={title} mt={0}>
      <Stack pt={{ base: 2, lg: 0 }} spacing={4}>
        <MarkdownViewer content={content} />

        <Box>
          {hasDelegateStatement ? (
            <Button
              as={Link}
              className="no-underline"
              fontSize="md"
              href={ROUTES.governance.delegate.profile(
                organization.slug,
                address,
              )}
              variant="primary"
              width={{ base: "100%", md: "auto" }}
            >
              View Delegate Profile
            </Button>
          ) : (
            <Button
              as={Link}
              className="no-underline"
              fontSize="md"
              href={ROUTES.governance.delegate.register(organization.slug)}
              isDisabled={isFetching}
              variant={
                // TODO(dan): we should generalize custom one-off buttons; we
                // only need to change this one button for wormhole
                organization.slug === "wormhole" ||
                organization.slug === "wormhole-private" ||
                organization.slug === "mainnet-wormhole" ||
                organization.slug === "testnet-wormhole"
                  ? "brand"
                  : "primary"
              }
              width={{ base: "100%", md: "auto" }}
            >
              Register
            </Button>
          )}
        </Box>
        <Link
          fontWeight="semibold"
          href={ROUTES.governance.delegates(organization.slug)}
        >
          View registered delegates
        </Link>
      </Stack>
    </CardContainer>
  )
}

export default OrganizationRegisterAsADelegate
