import type { FC } from "react"
import {
  Button,
  Flex,
  Icon,
  Link,
  Skeleton,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react"

import CardContainer from "common/components/CardContainer"
import type {
  Organization,
  OrganizationLatestForumActivitiesQuery,
} from "query/graphql"
import { useOrganizationLatestForumActivitiesQuery } from "query/graphql"
import type { Column } from "ui/components/Table"
import Table from "ui/components/Table"
import { useDevice } from "common/hooks/useDevice"
import { EXTERNAL_ROUTES } from "common/constants/routes"
import { getDateFromNow } from "common/helpers/date"
import type { ArrayElement } from "common/types/array"
import { ArrowUpRight } from "ui/components/icons/font-awesome/ArrowUpRight"

type Topics =
  OrganizationLatestForumActivitiesQuery["latestForumActivity"]["topics"]
type Topic = ArrayElement<Topics>

type Props = {
  organization: Organization
}
const OrganizationLatestForumActivities: FC<Props> = ({ organization }) => {
  const discourseBaseUrl =
    organization.metadata?.socials?.discourse ?? undefined

  const { onLargeDevice, onLittleDevice } = useDevice()
  const { data, isLoading } = useOrganizationLatestForumActivitiesQuery(
    {
      input: { id: organization.id },
    },
    { enabled: Boolean(discourseBaseUrl) },
  )

  if (!discourseBaseUrl) {
    return null
  }

  if (isLoading) {
    return (
      <CardContainer isTableView pb={2} title="Latest Forum Activities">
        <Stack spacing={2}>
          <Skeleton as="section" h="20px" mx={2} />
          <Skeleton as="section" h="30px" mx={2} />
          <Skeleton as="section" h="30px" mx={2} />
          <Skeleton as="section" h="30px" mx={2} />
          <Skeleton as="section" h="30px" mx={2} />
        </Stack>
      </CardContainer>
    )
  }

  const topics = data?.latestForumActivity?.topics ?? []

  const generateLargeColumns = (topics: Topic[]): Column[] => {
    return [
      {
        title: "Title",
        isNumeric: false,
        cells: topics.map((topic, idx) => {
          const route = topic.slug
            ? EXTERNAL_ROUTES.discourse.topic(discourseBaseUrl, topic.slug)
            : undefined

          if (route) {
            return (
              <Stack key={`topic-title-${idx}`} direction="column" spacing={0}>
                <Link isExternal href={route}>
                  <Text color="gray.700" fontSize="md">
                    {topic.title}
                  </Text>
                </Link>
                <Text color="gray.500" fontSize="sm">
                  {topic.originalPosterName}
                </Text>
              </Stack>
            )
          }

          return (
            <VStack key={`topic-title-${idx}`}>
              <Text color="gray.700" fontSize="md">
                {topic.title}
              </Text>
              <Text color="gray.500" fontSize="sm">
                {topic.originalPosterName}
              </Text>
            </VStack>
          )
        }),
      },
      {
        title: "Replies",
        isNumeric: true,
        cells: topics.map((topic, idx) => {
          return (
            <Text
              key={`topic-replies-${idx}`}
              color="gray.700"
              fontSize="sm"
              minWidth="60px"
              textAlign="right"
            >
              {topic.replyCount}
            </Text>
          )
        }),
      },
      {
        title: "Views",
        isNumeric: true,
        cells: topics.map((topic, idx) => {
          return (
            <Text
              key={`topic-views-${idx}`}
              color="gray.700"
              fontSize="sm"
              minWidth="60px"
              textAlign="right"
            >
              {topic.views}
            </Text>
          )
        }),
      },
      {
        title: "Activity",
        isNumeric: true,
        cells: topics.map((topic, idx) => {
          return (
            <Text
              key={`topic-activity-${idx}`}
              color="gray.700"
              fontSize="sm"
              minWidth="70px"
              textAlign="right"
            >
              {getDateFromNow(topic.lastPostedAt)}
            </Text>
          )
        }),
      },
    ]
  }

  const generateLittleColumns = (topics: Topic[]): Column[] => {
    return [
      {
        title: "Title",
        isNumeric: false,
        cells: topics.map((topic, idx) => {
          const route = topic.slug
            ? EXTERNAL_ROUTES.discourse.topic(discourseBaseUrl, topic.slug)
            : undefined

          if (route) {
            return (
              <Link key={`topic-title-mobile-${idx}`} isExternal href={route}>
                <Text color="gray.700" fontSize="sm">
                  {topic.title}
                </Text>
              </Link>
            )
          }

          return (
            <Text
              key={`topic-title-mobile-${idx}`}
              color="gray.700"
              fontSize="sm"
            >
              {topic.title}
            </Text>
          )
        }),
      },
    ]
  }

  const largeColumns: Column[] = generateLargeColumns(topics)
  const littleColumns: Column[] = generateLittleColumns(topics)

  return (
    <CardContainer isTableView pb={0} title="Latest Forum Activities">
      <Table
        columns={largeColumns}
        display={onLargeDevice}
        displayBorderTop={false}
        emptyMessage="No forum activity"
        id="org-forum-activity"
      />
      <Table
        columns={littleColumns}
        display={onLittleDevice}
        displayBorderTop={false}
        emptyMessage="No forum activity"
        id="org-forum-activity"
      />

      <Flex align="center" justify="center" px={2} py={2} width="100%">
        <Link
          isExternal
          className="no-underline"
          href={discourseBaseUrl}
          width="100%"
        >
          <Button
            rightIcon={<Icon as={ArrowUpRight} h={3} w={3} />}
            size="sm"
            variant="tertiary"
            width="100%"
          >
            View forum
          </Button>
        </Link>
      </Flex>
    </CardContainer>
  )
}

export default OrganizationLatestForumActivities
