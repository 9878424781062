import type { FC } from "react"
import React from "react"

import type { Column } from "ui/components/Table"
import Table from "ui/components/Table"
import { useDevice } from "common/hooks/useDevice"
import type { Proposal } from "query/graphql"
import TallyProposalIdentity from "tallyProposal/components/TallyProposalIdentity"
import TallyProposalCreatedDate from "tallyProposal/components/TallyProposalCreatedDate"

type Props = {
  slug: string
  tallyProposals: Proposal[]
}

const GovernanceTallyProposalsTable: FC<Props> = ({ slug, tallyProposals }) => {
  const { onLargeDevice, onLittleDevice } = useDevice()

  const largeColumns: Column[] = [
    {
      title: "Proposal",
      isNumeric: false,
      cells: tallyProposals.map((tallyProposal) => (
        <TallyProposalIdentity
          key={`governance-draft-tallyProposals-identity-${tallyProposal.id}`}
          slug={slug}
          tallyProposal={tallyProposal}
        />
      )),
    },
    {
      title: "Created",
      isNumeric: true,
      cells: tallyProposals.map((tallyProposal) => (
        <TallyProposalCreatedDate
          key={`governance-tallyProposals-created-date-${tallyProposal.id}`}
          createdAt={tallyProposal.createdAt}
        />
      )),
    },
  ]

  const littleColumns = [
    {
      title: "Proposal",
      isNumeric: false,
      cells: tallyProposals.map((tallyProposal) => (
        <TallyProposalIdentity
          key={`governance-tallyProposals-identity-${tallyProposal.id}`}
          slug={slug}
          tallyProposal={tallyProposal}
        />
      )),
    },
  ]

  return (
    <>
      <Table
        columns={largeColumns}
        display={onLargeDevice}
        displayBorderTop={false}
        emptyMessage="No draft proposals found"
        id="governance-draft-proposals"
      />
      <Table
        columns={littleColumns}
        display={onLittleDevice}
        displayBorderTop={false}
        emptyMessage="No draft proposals found"
        id="governance-draft-proposals"
      />
    </>
  )
}

export default GovernanceTallyProposalsTable
