import type { FC } from "react"
import { useEffect, useState } from "react"
import { AccountId as CaipAccountId } from "caip"
import { Box, Skeleton } from "@chakra-ui/react"

import { ROUTES } from "common/constants/routes"
import type { SourceTreasury } from "common/hooks/useTokenBalances"
import { useMultiAddressTokenBalances } from "common/hooks/useTokenBalances"
import type { Governor, Organization } from "query/graphql"
import {
  GovernorKind,
  useGovernanceTreasuryInformationQuery,
} from "query/graphql"
import { getChainIdParams } from "web3/helpers/chainId"
import type { TokenBalance } from "common/types/tokenBalances"
import { getMainnetChainIds } from "governance/helpers/governance"
import { getCustomSourceTreasuries } from "governance/helpers/treasury"
import { parseAccountId } from "web3/helpers/transformers"
import MobileCountBox from "common/components/MobileCountBox"
import { labelNumber } from "common/helpers/number"
import { pluralize } from "common/helpers/string"
import { useDevice } from "common/hooks/useDevice"

type OrganizationHomeTreasuryProps = {
  slug: string
  displayTop?: boolean
  isWhiteLabel: boolean
  governorIds: string[]
  organization: Organization
}

const OrganizationHomeTreasury: FC<OrganizationHomeTreasuryProps> = ({
  slug,
  governorIds,
  organization,
}) => {
  const [activeSourceTreasuries, setActiveSourceTreasuries] = useState<
    SourceTreasury[]
  >([])
  const sourceGovernanceIds = governorIds
  const { onLargeDevice, onLittleDevice } = useDevice()

  const { data, isLoading } = useGovernanceTreasuryInformationQuery({
    input: {
      filters: {
        organizationId: organization.id,
      },
    },
  })

  const {
    tokenBalances,
    isLoading: isBalanceLoading,
    isError,
  } = useMultiAddressTokenBalances(activeSourceTreasuries)

  const getTreasuryAddress = (governance: Governor) => {
    const { contracts, timelockId } = governance

    const { governor } = contracts
    const { address: governorAddress } = governor

    const treasuryAddress = timelockId
      ? CaipAccountId.parse(timelockId).address
      : governorAddress

    return treasuryAddress
  }

  useEffect(() => {
    if (data && data.governors) {
      const { governors } = data

      const mainnetChainIds = getMainnetChainIds()

      const filteredSourceTreasuries = (governors.nodes as Governor[])
        .filter((governance) => sourceGovernanceIds.includes(governance.id))
        .filter((governor) =>
          [
            GovernorKind.Multiprimary,
            GovernorKind.Multisecondary,
            GovernorKind.Single,
            GovernorKind.Hub,
            GovernorKind.Spoke,
          ].includes(governor.kind),
        )
        .map((governance) => {
          const { contracts, timelockId, id } = governance
          const { tokens } = contracts
          const { chainId } = parseAccountId(id)
          const { reference } = getChainIdParams(chainId)

          const treasuryAddress = getTreasuryAddress(governance as Governor)

          const isMainnetChain = mainnetChainIds.some(
            (chainFilter) => chainFilter === chainId,
          )

          const whitelistTokenAddresses = isMainnetChain
            ? tokens.map((token) => token.address)
            : []

          return {
            chainReference: reference,
            address: treasuryAddress,
            whitelistTokenAddresses,
            name: `${governance.name} ${timelockId ? "Timelock" : "Governor"}`,
          }
        })

      const customSourceTreasuries = getCustomSourceTreasuries({
        slug,
        sourceTreasuries: filteredSourceTreasuries,
      })

      setActiveSourceTreasuries(customSourceTreasuries)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, sourceGovernanceIds])

  if (isLoading) return null

  if (data === undefined) return null

  const [governance] = data.governors.nodes as Governor[]

  if (!governance) return null

  const getTokenPriceWarning = () => {
    const hasPriceData = tokenBalances?.nativeAsset?.tokenInfo?.fiat !== null

    return isError || !hasPriceData
  }

  const showTokenPriceWarning = getTokenPriceWarning()

  const getTotalBalanceLabel = () => {
    const balance = tokenBalances?.tokens?.reduce(
      (acc: number, token: TokenBalance) => {
        return acc + (Number(token?.tokenInfo?.fiat) || 0)
      },
      0,
    )

    const totalBalance =
      (balance || 0) + (tokenBalances?.nativeAsset?.tokenInfo?.fiat || 0)

    const labelNumberValue = labelNumber(totalBalance)

    return `$ ${labelNumberValue}`
  }

  const totalBalanceLabel = showTokenPriceWarning
    ? "N/A"
    : getTotalBalanceLabel()

  return (
    <>
      <Box display={onLargeDevice} h="110px" width="100%">
        <MobileCountBox
          footer={`${activeSourceTreasuries.length} treasury ${pluralize(
            activeSourceTreasuries.length,
            "source",
            "sources",
          )}`}
          h="110px"
          title="Treasury"
          url={ROUTES.governance.treasury.list(organization.slug)}
        >
          {isBalanceLoading ? <Skeleton h="32px" mt={2} /> : totalBalanceLabel}
        </MobileCountBox>
      </Box>

      <Box display={onLittleDevice} h="110px" width="100%">
        <MobileCountBox
          footer={`${activeSourceTreasuries.length} ${pluralize(
            activeSourceTreasuries.length,
            "source",
            "sources",
          )}`}
          h="110px"
          title="Treasury"
          url={ROUTES.governance.treasury.list(organization.slug)}
        >
          {isBalanceLoading ? <Skeleton h="32px" mt={2} /> : totalBalanceLabel}
        </MobileCountBox>
      </Box>
    </>
  )
}

export default OrganizationHomeTreasury
