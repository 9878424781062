import type { FC } from "react"
import React from "react"
import type { GetServerSideProps } from "next"
import { SimpleGrid, Stack } from "@chakra-ui/react"

import GovernanceProvider from "governance/context"
import MainLayout from "ui/layouts/main/MainLayout"
import MainLayoutHead from "ui/layouts/main/MainLayoutHead"
import MainLayoutContainer from "ui/layouts/main/MainLayoutContainer"
import MainLayoutFooter from "ui/layouts/main/MainLayoutFooter"
import ErrorBoundary from "common/components/ErrorBoundary"
import GovernanceProposals from "governance/components/GovernanceProposals"
import { getGovernanceIds } from "governance/helpers/governance"
import { getOrganizationSocialPreviewImage } from "organization/helpers/organization"
import {
  FeatureFlag,
  OrganizationProvider,
  isFeatureFlagOn,
} from "organization/providers/OrganizationProvider"
import { fetchDescriptionExceptionsByGovernorId } from "proposal/helpers/description"
import { DescriptionExceptionsProvider } from "proposal/providers/DescriptionExceptionsProvider"
import type { DescriptionException } from "proposal/types/exception"
import { isValidAccountId } from "web3/helpers/accountId"
import { fetchGovernanceById } from "governance/helpers/fetch"
import { ROUTES } from "common/constants/routes"
import type { Governor, Organization } from "query/graphql"
import type { OrganizationAnnouncement } from "organization/helpers/fetcher"
import {
  fetchGovernorsByOrganization,
  fetchOrganizationAnnouncement,
  fetchOrganizationBySlug,
} from "organization/helpers/fetcher"
import { isCorrectSlug, isWhitelabel } from "whitelabel/hooks/useIsWhiteLabel"
import type { GovernanceClaim } from "governance/hooks/useGovernanceClaimContent"
import {
  ClaimPeriod,
  fetchGovernanceContent,
} from "governance/hooks/useGovernanceClaimContent"
import OrganizationPreGovernorLayout from "organization/components/OrganizationPreGovernorLayout"
import MainLayoutHeader from "ui/layouts/main/MainLayoutHeader"
import { OrganizationMenu } from "ui/layouts/main/header/HeaderDAO"
import OrganizationHeader from "organization/components/OrganizationHeader"
import OrganizationHomeProposals from "organization/components/OrganizationHomeProposals"
import OrganizationRisingDelegates from "organization/components/OrganizationRisingDelegates"
import OrganizationLatestForumActivities from "organization/components/OrganizationLatestForumActivities"
import OrganizationHelpfulLinks from "organization/components/OrganizationHelpfulLink"
import OrganizationHomeProposalsMobile from "organization/components/OrganizationHomeProposalsMobile"
import OrganizationHomeDelegatesMobile from "organization/components/OrganizationHomeDelegatesMobile"
import { useDevice } from "common/hooks/useDevice"
import OrganizationHomeTreasury from "organization/components/OrganizationHomeTreasury"
import OrganizationSecurityCouncil from "organization/components/OrganizationSecurityCouncil"
import OrganizationMyVotingPower from "organization/components/OrganizationMyVotingPower"
import GovernanceClaimBanner from "governance/components/GovernanceClaimBanner"
import DelegationWeekBanner from "delegation/components/DelegationWeekBanner"
import OrganizationSafeList from "organization/components/OrganizationSafeList"
import { EditMode } from "common/constants/edit-mode"
import SecurityCouncilActionBanner from "delegation/components/SecurityCouncilActionBanner"
import OrganizationDisclaimer from "organization/components/OrganizationDisclaimer"

type Props = {
  governors: Governor[]
  isWhiteLabel: boolean
  governorClaim?: GovernanceClaim
  organizationAnnouncement?: OrganizationAnnouncement
  descriptionExceptions: DescriptionException[]
  organization: Organization
  isNewLayoutEnabled: boolean
  isHideTreasuryEnabled: boolean
}

const GovernancePage: FC<Props> = ({
  governors,
  isWhiteLabel,
  governorClaim,
  organizationAnnouncement,
  descriptionExceptions,
  organization,
  isHideTreasuryEnabled = true,
}) => {
  const governor = governors?.length > 0 ? governors[0] : undefined

  const { slug, name } = organization
  const governorIds = getGovernanceIds(governors)

  const { onLittleDevice, onLargeDevice } = useDevice()

  const title = isWhiteLabel ? `${name}` : `Tally | ${name}`
  const preview = {
    title,
    image: getOrganizationSocialPreviewImage(organization),
    description: `${name} proposals and contributors`,
  }

  return (
    <ErrorBoundary section="Governance">
      <DescriptionExceptionsProvider
        descriptionExceptions={descriptionExceptions}
      >
        <OrganizationProvider organization={organization}>
          <GovernanceProvider governor={governor}>
            <MainLayout>
              <MainLayoutHead preview={preview} title={title} />
              <MainLayoutHeader
                activeOrganizationMenu={OrganizationMenu.Home}
                isWhiteLabel={isWhiteLabel}
                organization={organization}
              />

              <MainLayoutContainer>
                <>
                  {/* DAO has an announcement but no governor */}
                  {organizationAnnouncement && !governor ? (
                    <>
                      <ErrorBoundary section="OrganizationHeader">
                        <OrganizationHeader
                          isNewLayoutEnabled
                          organization={organization}
                          organizationAnnouncement={organizationAnnouncement}
                        />
                      </ErrorBoundary>

                      <ErrorBoundary section="OrganizationAnnouncementLayout">
                        <OrganizationPreGovernorLayout
                          organization={organization}
                          organizationAnnouncement={organizationAnnouncement}
                        />
                      </ErrorBoundary>
                    </>
                  ) : null}
                  {/* end: DAO has an announcement but no governor */}

                  {/* DAO has a governor */}
                  {governor ? (
                    <>
                      <Stack display={onLargeDevice}>
                        {isWhiteLabel ? (
                          <ErrorBoundary section="GovernanceClaimBanner">
                            <GovernanceClaimBanner
                              governorClaim={governorClaim}
                              governorId={governor.id}
                              governorSlug={slug}
                            />
                          </ErrorBoundary>
                        ) : null}

                        <ErrorBoundary section="DelegationWeekBanner">
                          <DelegationWeekBanner />
                        </ErrorBoundary>

                        <ErrorBoundary section="SecurityCouncilActionBanner">
                          <SecurityCouncilActionBanner
                            organization={organization}
                          />
                        </ErrorBoundary>

                        <ErrorBoundary section="OrganizationHeader">
                          <OrganizationHeader
                            isNewLayoutEnabled
                            organization={organization}
                            organizationAnnouncement={organizationAnnouncement}
                          />
                        </ErrorBoundary>

                        {isWhiteLabel &&
                        governorClaim?.claimPeriod ===
                          ClaimPeriod.NotStarted ? null : (
                          <Stack
                            direction={{ base: "column", md: "row" }}
                            spacing={3}
                          >
                            <Stack
                              direction="column"
                              mt={2}
                              spacing={0}
                              width={{ base: "100%", md: "70%" }}
                            >
                              <SimpleGrid
                                columns={isHideTreasuryEnabled ? 2 : 3}
                                mb={1}
                                spacing={2}
                              >
                                <ErrorBoundary section="OrganizationHomeDelegatesMobile">
                                  <OrganizationHomeDelegatesMobile
                                    organization={organization}
                                  />
                                </ErrorBoundary>

                                <ErrorBoundary section="OrganizationHomeProposalsMobile">
                                  <OrganizationHomeProposalsMobile
                                    organization={organization}
                                  />
                                </ErrorBoundary>

                                {!isHideTreasuryEnabled && governor ? (
                                  <ErrorBoundary section="OrganizationHomeTreasury">
                                    <OrganizationHomeTreasury
                                      governorIds={governorIds}
                                      isWhiteLabel={isWhiteLabel}
                                      organization={organization}
                                      slug={slug}
                                    />
                                  </ErrorBoundary>
                                ) : null}
                              </SimpleGrid>

                              {governors?.length > 0 ? (
                                <ErrorBoundary section="OrganizationHomeProposals">
                                  <OrganizationHomeProposals
                                    governorIds={governorIds}
                                    isWhiteLabel={isWhiteLabel}
                                    organization={organization}
                                  />
                                </ErrorBoundary>
                              ) : null}

                              {governor ? (
                                <ErrorBoundary section="OrganizationRisingDelegates">
                                  <OrganizationRisingDelegates
                                    governorId={governor.id}
                                    isWhiteLabel={isWhiteLabel}
                                    organization={organization}
                                  />
                                </ErrorBoundary>
                              ) : null}

                              <ErrorBoundary section="OrganizationLatestForumActivities">
                                <OrganizationLatestForumActivities
                                  organization={organization}
                                />
                              </ErrorBoundary>

                              {organization.slug === "arbitrum" ? (
                                <ErrorBoundary section="OrganizationSafeList">
                                  {organization?.id ? (
                                    <OrganizationSafeList
                                      displaySigners={false}
                                      editMode={EditMode.Governance}
                                      isWhiteLabel={isWhiteLabel}
                                      organizationId={organization.id}
                                    />
                                  ) : null}
                                </ErrorBoundary>
                              ) : null}
                            </Stack>
                            <Stack
                              alignSelf="flex-start"
                              mt={2}
                              spacing={1}
                              width={{ base: "100%", md: "30%" }}
                            >
                              <ErrorBoundary section="OrganizationSecurityCouncil">
                                <OrganizationSecurityCouncil
                                  organization={organization}
                                />
                              </ErrorBoundary>

                              <ErrorBoundary section="OrganizationVotingPower">
                                <OrganizationMyVotingPower
                                  organization={organization}
                                />
                              </ErrorBoundary>

                              <ErrorBoundary section="OrganizationHelpfulLinks">
                                <OrganizationHelpfulLinks
                                  displayOrgLinks={false}
                                  displayTitle={false}
                                  externalLinks={
                                    organizationAnnouncement?.externalLinks
                                  }
                                  organization={organization}
                                />
                              </ErrorBoundary>

                              <ErrorBoundary section="OrganizationDisclaimer">
                                <OrganizationDisclaimer
                                  organization={organization}
                                />
                              </ErrorBoundary>
                            </Stack>
                          </Stack>
                        )}
                      </Stack>
                      <Stack display={onLittleDevice} px={0}>
                        <Stack direction="column" mb={4} spacing={1}>
                          {isWhiteLabel ? (
                            <ErrorBoundary section="GovernanceClaimBanner">
                              <GovernanceClaimBanner
                                governorClaim={governorClaim}
                                governorId={governor.id}
                                governorSlug={slug}
                              />
                            </ErrorBoundary>
                          ) : null}

                          <ErrorBoundary section="SecurityCouncilActionBanner">
                            <SecurityCouncilActionBanner
                              organization={organization}
                            />
                          </ErrorBoundary>

                          <ErrorBoundary section="OrganizationHeader">
                            <OrganizationHeader
                              isNewLayoutEnabled
                              organization={organization}
                              organizationAnnouncement={
                                organizationAnnouncement
                              }
                            />
                          </ErrorBoundary>

                          <ErrorBoundary section="OrganizationSecurityCouncil">
                            <OrganizationSecurityCouncil
                              organization={organization}
                            />
                          </ErrorBoundary>

                          <SimpleGrid columns={2} mb={1} spacing={2}>
                            <ErrorBoundary section="OrganizationHomeDelegatesMobile">
                              <OrganizationHomeDelegatesMobile
                                organization={organization}
                              />
                            </ErrorBoundary>
                            <ErrorBoundary section="OrganizationHomeProposalsMobile">
                              <OrganizationHomeProposalsMobile
                                organization={organization}
                              />
                            </ErrorBoundary>
                          </SimpleGrid>

                          <SimpleGrid
                            columns={isHideTreasuryEnabled ? 1 : 2}
                            mb={1}
                            spacing={2}
                          >
                            {!isHideTreasuryEnabled && governor ? (
                              <ErrorBoundary section="OrganizationHomeTreasury">
                                <OrganizationHomeTreasury
                                  governorIds={governorIds}
                                  isWhiteLabel={isWhiteLabel}
                                  organization={organization}
                                  slug={slug}
                                />
                              </ErrorBoundary>
                            ) : null}
                            <ErrorBoundary section="OrganizationVotingPower">
                              <OrganizationMyVotingPower
                                organization={organization}
                              />
                            </ErrorBoundary>
                          </SimpleGrid>

                          {governors?.length > 0 ? (
                            <ErrorBoundary section="OrganizationHomeProposals">
                              <OrganizationHomeProposals
                                displayNavBar={false}
                                governorIds={governorIds}
                                isWhiteLabel={isWhiteLabel}
                                organization={organization}
                                title="Proposals"
                              />
                            </ErrorBoundary>
                          ) : null}

                          <ErrorBoundary section="OrganizationHelpfulLinks">
                            <OrganizationHelpfulLinks
                              displayOrgLinks={false}
                              displayTitle={false}
                              externalLinks={
                                organizationAnnouncement?.externalLinks
                              }
                              organization={organization}
                            />
                          </ErrorBoundary>

                          <ErrorBoundary section="OrganizationLatestForumActivities">
                            <OrganizationLatestForumActivities
                              organization={organization}
                            />
                          </ErrorBoundary>

                          <ErrorBoundary section="OrganizationDisclaimer">
                            <OrganizationDisclaimer
                              organization={organization}
                            />
                          </ErrorBoundary>
                        </Stack>
                      </Stack>
                    </>
                  ) : null}
                  {/* end: DAO has a governor */}

                  {/* DAO doesn't have governor nor organizationAnnouncement */}
                  {!governor && !organizationAnnouncement ? (
                    <Stack>
                      <ErrorBoundary section="OrganizationHeader">
                        <OrganizationHeader organization={organization} />
                      </ErrorBoundary>

                      {isWhiteLabel &&
                      governorClaim?.claimPeriod ===
                        ClaimPeriod.NotStarted ? null : (
                        <Stack
                          direction={{ base: "column", md: "row" }}
                          spacing={4}
                        >
                          <Stack
                            direction="column"
                            mt={4}
                            spacing={0}
                            width={{ base: "100%", md: "70%" }}
                          >
                            <ErrorBoundary section="GovernanceProposals">
                              <GovernanceProposals
                                chainId=""
                                governorIds={governorIds}
                                isWhiteLabel={isWhiteLabel}
                                organization={organization}
                                slug={slug}
                              />
                            </ErrorBoundary>

                            <ErrorBoundary section="OrganizationLatestForumActivities">
                              <OrganizationLatestForumActivities
                                organization={organization}
                              />
                            </ErrorBoundary>
                          </Stack>
                          <Stack
                            alignSelf="flex-start"
                            mt={4}
                            spacing={1}
                            width={{ base: "100%", md: "30%" }}
                          >
                            <ErrorBoundary section="OrganizationHelpfulLinks">
                              <OrganizationHelpfulLinks
                                displayOrgLinks={false}
                                displayTitle={false}
                                organization={organization}
                              />
                            </ErrorBoundary>
                          </Stack>
                        </Stack>
                      )}
                    </Stack>
                  ) : null}
                  {/* end: DAO doesn't have governor nor organizationAnnouncement */}
                </>
              </MainLayoutContainer>
              <MainLayoutFooter isWhiteLabel={isWhiteLabel} />
            </MainLayout>
          </GovernanceProvider>
        </OrganizationProvider>
      </DescriptionExceptionsProvider>
    </ErrorBoundary>
  )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { query } = context
  const { governanceId: governorIdParam } = query
  const slug = governorIdParam as string

  // Find governance by id and redirect to /gov/:slug
  if (isValidAccountId(slug)) {
    const governance = await fetchGovernanceById(slug)

    if (governance && governance?.organization) {
      return {
        redirect: {
          permanent: false,
          destination: ROUTES.governance.id(governance.organization.slug),
        },
      }
    }
  }

  if (isWhitelabel(context)) {
    if (!isCorrectSlug(context, slug)) {
      return {
        redirect: {
          permanent: false,
          destination: "/404",
        },
      }
    }
  }

  const organization = await fetchOrganizationBySlug(slug)

  if (!organization) {
    return {
      redirect: {
        permanent: false,
        destination: "/404",
      },
    }
  }

  const governors = await fetchGovernorsByOrganization(organization.id)

  const governor = governors?.length > 0 ? governors[0] : undefined

  const { descriptionExceptions } =
    await fetchDescriptionExceptionsByGovernorId(organization.governorIds)

  const governorClaim = governor
    ? await fetchGovernanceContent({
        governanceId: governor.id,
      })
    : null
  const organizationAnnouncement = await fetchOrganizationAnnouncement({
    organizationId: organization.id,
  })

  const isHideTreasuryEnabled = isFeatureFlagOn(
    organization.features,
    FeatureFlag.HideTreasury,
  )

  return {
    props: {
      organization,
      governors,
      isWhiteLabel: isWhitelabel(context),
      governorClaim,
      organizationAnnouncement,
      descriptionExceptions,
      isHideTreasuryEnabled,
    },
  }
}

export default GovernancePage
