import React from "react"
import { createIcon } from "@chakra-ui/icons"

const TelegramIcon = createIcon({
  displayName: "TelegramIcon",
  viewBox: "0 0 14 12",
  path: (
    <g>
      <path
        d="M13.3625 2.04041C13.5265 1.27478 13.089 0.973999 12.5968 1.16541L1.71403 5.349C0.975749 5.64978 1.00309 6.05994 1.60466 6.25134L4.36637 7.099L10.8195 3.05212C11.1203 2.83337 11.4211 2.97009 11.175 3.1615L5.95231 7.86462L5.76091 10.7357C6.06169 10.7357 6.17106 10.6263 6.33512 10.4623L7.67497 9.14978L10.4914 11.2279C11.0109 11.5287 11.3937 11.3646 11.5304 10.7631L13.3625 2.04041Z"
        fill="currentColor"
      />
    </g>
  ),
})

export default TelegramIcon
