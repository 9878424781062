import { useRef, type FC } from "react"
import {
  Stack,
  Grid,
  Skeleton,
  GridItem,
  Text,
  Spacer,
  Flex,
  Button,
  Divider,
  Box,
} from "@chakra-ui/react"
import { isAddress } from "@ethersproject/address"
import truncate from "truncate"

import type { Delegate, Organization } from "query/graphql"
import { DelegatesSortBy, useDelegatesQuery } from "query/graphql"
import CardContainer from "common/components/CardContainer"
import UserAvatar from "common/components/UserAvatar"
import { getDisplayName } from "user/helpers/user"
import { getWeightLabel, stringToBigNumber } from "common/helpers/bignumber"
import { shortString } from "common/helpers/string"
import { ROUTES } from "common/constants/routes"
import { getWhiteLabelRoute } from "whitelabel/utils/breadcrumb"
import Link from "common/components/Link"
import {
  FeatureFlag,
  useOrganization,
} from "organization/providers/OrganizationProvider"

type Props = {
  isWhiteLabel: boolean
  governorId: string
  organization: Organization
}
const OrganizationRisingDelegates: FC<Props> = ({
  isWhiteLabel,
  governorId,
  organization,
}) => {
  const ref = useRef(null)

  const { isFeatureFlagOn } = useOrganization()

  const isFeaturedDelegatesEnabled = isFeatureFlagOn(
    FeatureFlag.DelegatesSortedByPriority,
  )

  const title = isFeaturedDelegatesEnabled
    ? "Featured delegates"
    : "Rising delegates"

  const isMultiGov = organization.governorIds.length > 1

  const { data: delegatesData, isLoading } = useDelegatesQuery({
    input: {
      filters: {
        organizationId: organization.id,
        governorId:
          isMultiGov || isFeaturedDelegatesEnabled ? undefined : governorId,
      },
      sort: {
        isDescending: true,
        sortBy: isFeaturedDelegatesEnabled
          ? DelegatesSortBy.Prioritized
          : DelegatesSortBy.Votes,
      },
      page: { limit: 4 },
    },
  })

  if (isLoading) {
    return (
      <Box ref={ref}>
        <CardContainer title={title}>
          <Stack as="section" spacing={6} w="full">
            <Grid
              gap={2}
              gridTemplateColumns={{ md: "repeat(2, minmax(0, 1fr))" }}
            >
              <Skeletons />
            </Grid>
          </Stack>
        </CardContainer>
      </Box>
    )
  }

  if (
    !delegatesData ||
    !delegatesData.delegates ||
    !delegatesData.delegates.nodes ||
    delegatesData.delegates.nodes.length <= 0
  )
    return (
      <Box ref={ref}>
        <CardContainer title={title}>
          <Stack as="section" spacing={6} w="full">
            <Flex
              align="center"
              bg="gray.50"
              h={16}
              justify="center"
              width="100%"
            >
              <Text color="gray.600" textStyle="body.bold.md">
                No delegates yet
              </Text>
            </Flex>
          </Stack>
        </CardContainer>
      </Box>
    )

  const { delegates } = delegatesData

  return (
    <Box ref={ref}>
      <CardContainer isFullHeightView isTableView pb={0} title={title}>
        <Stack as="section" px={2} py={2} spacing={6} w="full">
          <Grid
            gap={2}
            gridTemplateColumns={{ md: "repeat(2, minmax(0, 1fr))" }}
          >
            {(delegates.nodes as Delegate[])?.map((delegate, index) => (
              <GridItem key={delegate.account.address}>
                <DelegateCard
                  key={index}
                  delegate={delegate}
                  isWhiteLabel={isWhiteLabel}
                  slug={organization.slug}
                />
              </GridItem>
            ))}
          </Grid>
        </Stack>

        <Divider />

        <Flex align="center" justify="center" px={2} py={2} width="100%">
          <Link
            className="no-underline"
            href={ROUTES.governance.delegates(
              organization.slug,
              isFeaturedDelegatesEnabled
                ? DelegatesSortBy.Prioritized
                : undefined,
            )}
            width="100%"
          >
            <Button size="sm" variant="tertiary" width="100%">
              View all
            </Button>
          </Link>
        </Flex>
      </CardContainer>
    </Box>
  )
}

const Skeletons = () => (
  <>
    {new Array(4).fill(0).map((_, index) => (
      <Skeleton key={index} borderRadius="lg" height="66px" />
    ))}
  </>
)

type DelegateCardProps = {
  delegate: Delegate
  slug: string
  isWhiteLabel: boolean
}
const DelegateCard: FC<DelegateCardProps> = ({
  delegate,
  slug,
  isWhiteLabel,
}) => {
  const displayName = getDisplayName(delegate.account)

  const { votesCount, token } = delegate
  const bigVoteWeight = stringToBigNumber(votesCount)

  const getVotingPower = () => {
    if (!token) return null

    const { decimals, symbol } = token
    const voteWeightFormatted =
      decimals !== undefined
        ? getWeightLabel(bigVoteWeight, decimals)
        : undefined

    return { voteWeightFormatted, symbol }
  }

  const votingPower = getVotingPower()

  const address = delegate.account.address

  const route = isWhiteLabel
    ? getWhiteLabelRoute(ROUTES.governance.delegate.profile(slug, address))
    : ROUTES.governance.delegate.profile(slug, address)

  return (
    <Stack
      _hover={{
        borderColor: "gray.300",
      }}
      align="center"
      background="white"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="lg"
      direction="row"
      p={4}
    >
      <UserAvatar
        address={delegate.account.address}
        shouldShowBadge={false}
        size="sm"
        src={delegate.account.picture}
      />
      <Link href={route}>
        <Text color="gray.900" fontSize="sm" fontWeight="medium">
          {isAddress(displayName)
            ? shortString(displayName)
            : truncate(displayName, 20)}
        </Text>
      </Link>
      <Spacer />

      {votingPower ? (
        <Text color="gray.700" fontSize="sm" fontWeight="medium">
          {votingPower.voteWeightFormatted} {votingPower.symbol}
        </Text>
      ) : null}
    </Stack>
  )
}

export default OrganizationRisingDelegates
