import { useEffect, useState } from "react"
import type { GetServerSidePropsContext } from "next"

import { useFeatureFlag } from "common/hooks/useFeatureFlag"
import { WHITELABEL_GOVERNORS_IDS } from "whitelabel/constants/governorId"
import { getOriginRequest } from "whitelabel/helpers/whitelabel"

export function isWhitelabel(context: GetServerSidePropsContext) {
  const origin = getOriginRequest(context)

  if (origin === undefined) {
    return false
  }

  // @ts-expect-error nothing to do
  if (WHITELABEL_GOVERNORS_IDS[origin as string]) {
    return true
  }

  return false
}

export function isCorrectSlug(
  context: GetServerSidePropsContext,
  governorSlugParam: string,
) {
  const origin = getOriginRequest(context)

  // @ts-expect-error nothing to do
  const whiteLabelGovernorSlug = WHITELABEL_GOVERNORS_IDS[origin as string]

  return whiteLabelGovernorSlug === governorSlugParam
}

export function useIsWhiteLabel() {
  const [isWhitelabel, setIsWhitelabel] = useState(false)
  const { isFeatureFlagOn } = useFeatureFlag()

  useEffect(() => {
    if (typeof window === undefined) return

    // @ts-expect-error nothing to do
    const isOriginWhiteLabel = WHITELABEL_GOVERNORS_IDS[window.location.host]

    if (!isOriginWhiteLabel) {
      return
    }

    setIsWhitelabel(true)
  }, [isFeatureFlagOn])

  return isWhitelabel
}
