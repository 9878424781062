import { useEffect, useState } from "react"

import { EXTERNAL_ENDPOINTS } from "common/constants/endpoints"
import { fetcher } from "common/helpers/fetcher"

type GovernanceClaimContentValues = {
  governanceClaim: GovernanceClaim | undefined
  isLoading: boolean
}

export enum ClaimPeriod {
  Active = "Active",
  Completed = "Completed",
  NotStarted = "NotStarted",
}

export type GovernanceClaim = {
  eligibilityCriteria: string | undefined
  learnAbout: string | undefined
  claimPeriod: ClaimPeriod
  registerAsADelegate: string | undefined
  watchForPhishingAttempts: string | undefined
  timeline: string[]
  announcementUrl: string | undefined
  eligibilityConditions?: {
    name: string
    conditions: { conditionKey: string; label: string }[]
  }[]
  originalEligibilityFileUrl: string | undefined
}

export const useGovernanceClaimContent = ({
  governanceId,
}: {
  governanceId: string
}): GovernanceClaimContentValues => {
  const [governanceClaim, setGovernanceClaim] = useState<
    GovernanceClaim | undefined
  >(undefined)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    fetchGovernanceContent({ governanceId })
      .then((data: GovernanceClaim | null) => {
        if (data) {
          setGovernanceClaim(data)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [governanceId])

  return { isLoading, governanceClaim }
}

type GovernanceContentResponse = {
  governanceClaim: GovernanceClaim | undefined
}

export const fetchGovernanceContent = async ({
  governanceId,
}: {
  governanceId: string
}): Promise<GovernanceClaim | null> => {
  try {
    const query = `
      query {
        governanceClaim(where: {governanceId: "${governanceId}"}) {
            governanceId
            eligibilityCriteria
            learnAbout
            claimPeriod
            registerAsADelegate
            watchForPhishingAttempts
            timeline
            announcementUrl
            eligibilityConditions
            originalEligibilityFileUrl
        }
      }`
    const endpoint = EXTERNAL_ENDPOINTS.graphCms()
    const response = await fetcher.gql<GovernanceContentResponse | undefined>({
      query,
      endpoint,
      onError: (error) => console.log(error),
    })

    const governanceClaim = response?.governanceClaim ?? null

    return governanceClaim
  } catch (err) {
    return null
  }
}
