import {
  getArbitrumNovaReference,
  getArbitrumReference,
  getOptimismReference,
} from "web3/helpers/chainReference"
import type { SourceTreasury } from "common/hooks/useTokenBalances"
import { isSameAddress } from "web3/helpers/address"

// This is a custom solution to support special cases on some DAOs
// In the future, this data could be moved to the API/BE
const CUSTOM_SOURCE_TREASURIES: Record<
  string,
  Record<string, SourceTreasury[]> | undefined
> = {
  "https://api.tally.xyz": {
    hai: [
      {
        chainReference: getOptimismReference(),
        address: "0xDCb421Cc4Cbb7267F3b2cAcAb44Ec18AEbEd6724",
        whitelistTokenAddresses: [
          "0xf467C7d5a4A9C4687fFc7986aC6aD5A4c81E1404",
          "0x4200000000000000000000000000000000000042",
        ],
        name: "HAI Safe Treasury",
      },
    ],
    arbitrum: [
      {
        chainReference: getArbitrumReference(),
        address: "0xF3FC178157fb3c87548bAA86F9d24BA38E649B58",
        whitelistTokenAddresses: ["0x912CE59144191C1204E64559FE8253a0e49E6548"],
        name: "Arbitrum Treasury",
      },
      {
        chainReference: getArbitrumReference(),
        address: "0x2E041280627800801E90E9Ac83532fadb6cAd99A",
        whitelistTokenAddresses: ["0x912CE59144191C1204E64559FE8253a0e49E6548"],
        name: "L1 Surplus Fee",
      },
      {
        chainReference: getArbitrumReference(),
        address: "0x32e7AF5A8151934F3787d0cD59EB6EDd0a736b1d",
        whitelistTokenAddresses: ["0x912CE59144191C1204E64559FE8253a0e49E6548"],
        name: "L2 Surplus Fee",
      },
      {
        chainReference: getArbitrumReference(),
        address: "0xbF5041Fc07E1c866D15c749156657B8eEd0fb649",
        whitelistTokenAddresses: ["0x912CE59144191C1204E64559FE8253a0e49E6548"],
        name: "L2 Base Fee",
      },
      {
        chainReference: getArbitrumNovaReference(),
        address: "0x509386DbF5C0BE6fd68Df97A05fdB375136c32De",
        whitelistTokenAddresses: [],
        name: "L1 Surplus Fee (Nova)",
      },
      {
        chainReference: getArbitrumNovaReference(),
        address: "0x3B68a689c929327224dBfCe31C1bf72Ffd2559Ce",
        whitelistTokenAddresses: [],
        name: "L2 Surplus Fee (Nova)",
      },
      {
        chainReference: getArbitrumNovaReference(),
        address: "0x9fCB6F75D99029f28F6F4a1d277bae49c5CAC79f",
        whitelistTokenAddresses: [],
        name: "L2 Base Fee (Nova)",
      },
      {
        chainReference: getArbitrumNovaReference(),
        address: "0xf7951d92b0c345144506576ec13ecf5103ac905a",
        whitelistTokenAddresses: [],
        name: "Arbitrum Fee Treasury (Nova)",
      },
    ],
  },
  "https://api.staging.tally.xyz": {
    arbitrum: [
      {
        chainReference: getArbitrumReference(),
        address: "0xF3FC178157fb3c87548bAA86F9d24BA38E649B58",
        whitelistTokenAddresses: ["0x912CE59144191C1204E64559FE8253a0e49E6548"],
        name: "Arbitrum Treasury",
      },
      {
        chainReference: getArbitrumReference(),
        address: "0x2E041280627800801E90E9Ac83532fadb6cAd99A",
        whitelistTokenAddresses: ["0x912CE59144191C1204E64559FE8253a0e49E6548"],
        name: "L1 Surplus Fee",
      },
      {
        chainReference: getArbitrumReference(),
        address: "0x32e7AF5A8151934F3787d0cD59EB6EDd0a736b1d",
        whitelistTokenAddresses: ["0x912CE59144191C1204E64559FE8253a0e49E6548"],
        name: "L2 Surplus Fee",
      },
      {
        chainReference: getArbitrumReference(),
        address: "0xbF5041Fc07E1c866D15c749156657B8eEd0fb649",
        whitelistTokenAddresses: ["0x912CE59144191C1204E64559FE8253a0e49E6548"],
        name: "L2 Base Fee",
      },
      {
        chainReference: getArbitrumNovaReference(),
        address: "0x509386DbF5C0BE6fd68Df97A05fdB375136c32De",
        whitelistTokenAddresses: [],
        name: "L1 Surplus Fee (Nova)",
      },
      {
        chainReference: getArbitrumNovaReference(),
        address: "0x3B68a689c929327224dBfCe31C1bf72Ffd2559Ce",
        whitelistTokenAddresses: [],
        name: "L2 Surplus Fee (Nova)",
      },
      {
        chainReference: getArbitrumNovaReference(),
        address: "0x9fCB6F75D99029f28F6F4a1d277bae49c5CAC79f",
        whitelistTokenAddresses: [],
        name: "L2 Base Fee (Nova)",
      },
      {
        chainReference: getArbitrumNovaReference(),
        address: "0xf7951d92b0c345144506576ec13ecf5103ac905a",
        whitelistTokenAddresses: [],
        name: "Arbitrum Fee Treasury (Nova)",
      },
    ],
  },
}

export const getCustomSourceTreasuries = ({
  slug,
  sourceTreasuries,
}: {
  slug: string
  sourceTreasuries: SourceTreasury[]
}) => {
  const daoCustomSourceTreasuries =
    CUSTOM_SOURCE_TREASURIES?.[process.env.NEXT_PUBLIC_TALLY_API_URL]?.[slug]

  const customSourceTreasuries = daoCustomSourceTreasuries
    ? [...sourceTreasuries, ...daoCustomSourceTreasuries]
    : sourceTreasuries

  // Remove duplicated addresses
  return customSourceTreasuries.filter(
    (record, index, self) =>
      index === self.findIndex((t) => isSameAddress(t.address, record.address)),
  )
}

export const getCustomProposalSourceTreasuryAddress = ({
  slug,
}: {
  slug: string
}) => {
  const customSourceTreasuries =
    CUSTOM_SOURCE_TREASURIES?.[process.env.NEXT_PUBLIC_TALLY_API_URL]?.[slug]

  return customSourceTreasuries ? customSourceTreasuries[0].address : undefined
}
