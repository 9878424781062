import { useMemo, type FC } from "react"
import { Text, type StackProps } from "@chakra-ui/react"

import { ROUTES } from "common/constants/routes"
import { useMe } from "user/providers/MeProvider"
import TitleNavigationTabs from "ui/components/TitleNavigationTabs"

type Props = {
  slug: string
}
const ProposalsListNavTabs: FC<Props & StackProps> = ({
  slug,
  ...stackProps
}) => {
  const me = useMe()

  const tabs = useMemo(() => {
    const draftsTabs = me
      ? [
          {
            label: "My Drafts",
            url: ROUTES.governance.drafts.list(slug),
          },
        ]
      : []

    const tabs = [
      ...draftsTabs,
      {
        label: "Onchain",
        url: ROUTES.governance.proposals.list(slug),
        isActive: true,
      },
    ]

    return tabs
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me?.address, slug])

  return (
    <TitleNavigationTabs
      tabs={tabs}
      topLeft={
        <Text flex="1" fontSize="md" mt={-1} pl={4}>
          Proposals
        </Text>
      }
      {...stackProps}
    />
  )
}

export default ProposalsListNavTabs
