import type { ComponentWithAs, IconProps, StackProps } from "@chakra-ui/react"
import {
  Spacer,
  Stack,
  Text,
  Icon,
  HStack,
  Flex,
  Button,
} from "@chakra-ui/react"
import type { FC, ReactNode } from "react"

import Link from "common/components/Link"
import { ArrowRight } from "ui/components/icons/font-awesome/ArrowRight"

export type OrangeBannerButton = {
  label: string
  mobileLabel?: string
  url: string
  isExternal?: boolean
  displayArrow?: boolean
  isPrimary?: boolean
}

type Props = {
  title?: string
  description?: string | ReactNode
  buttons?: OrangeBannerButton[]
  children?: ReactNode
  icon?: ComponentWithAs<"svg", IconProps>
  overrideBG?: string
  bgImageUrl?: string
}
const OrangeBanner: FC<Props & StackProps> = ({
  title,
  description,
  children,
  icon,
  buttons = [],
  overrideBG = "linear-gradient(90deg, rgba(240, 129, 49, 1), rgba(243, 86, 36, 1))",
  bgImageUrl,
  ...stackProps
}) => {
  const displayButtons = buttons ? buttons.length > 0 : false

  const defaultButton = displayButtons ? buttons[0] : undefined

  const bannerContent = (
    <Stack
      align={{ base: "left", sm: "center" }}
      backgroundImage={`url(${bgImageUrl})`}
      backgroundPosition="center"
      backgroundSize="cover"
      bg={!bgImageUrl ? overrideBG : ""}
      borderRadius="md"
      direction={{ base: "column", sm: "row" }}
      mb={2}
      position="relative"
      px={{ base: 4, sm: 6 }}
      py={4}
      width="100%"
      {...stackProps}
    >
      {icon ? (
        <Flex display={{ base: "none", sm: "block" }} pr={2}>
          <Icon as={icon} color="white" fill="white" h={8} w={8} />
        </Flex>
      ) : null}
      <Stack direction="column" spacing={0} zIndex={2}>
        {title ? (
          <Text as="h3" color="white" fontSize="lg" fontWeight="bold">
            {title}
          </Text>
        ) : null}
        {description ? (
          <Text color="white" fontSize="md" fontWeight="regular">
            {description}
          </Text>
        ) : null}
      </Stack>
      {children ? (
        <Text
          as="h3"
          color="white"
          fontWeight="bold"
          textStyle="lg"
          width="100%"
          zIndex={2}
        >
          {children}
        </Text>
      ) : null}
      <Spacer />
      {displayButtons && buttons ? (
        <Stack direction={{ base: "column", sm: "row" }} zIndex={2}>
          {buttons.map((button, idx) => {
            const { label, url, isExternal, isPrimary } = button

            return (
              <Link
                key={`button-${idx}`}
                className="no-underline"
                href={url}
                isExternal={isExternal}
              >
                <Button
                  _hover={{ bg: isPrimary ? "gray.100" : "" }}
                  bgColor={isPrimary ? "white" : "auto"}
                  textColor={isPrimary ? "gray.600" : "white"}
                  variant={isPrimary ? "primary" : "secondary"}
                  width="100%"
                >
                  <HStack>
                    <Text fontWeight="bold">{label}</Text>
                    {button.displayArrow ? (
                      <Icon
                        as={ArrowRight}
                        color={isPrimary ? "gray.600" : "white"}
                        h={{ base: 4, lg: 4 }}
                        w={{ base: 4, lg: 4 }}
                      />
                    ) : null}
                  </HStack>
                </Button>
              </Link>
            )
          })}
        </Stack>
      ) : null}
    </Stack>
  )

  if (defaultButton && defaultButton.url) {
    return (
      <Link
        className="no-underline"
        href={defaultButton.url}
        isExternal={defaultButton.isExternal}
      >
        {bannerContent}
      </Link>
    )
  }

  return bannerContent
}

export default OrangeBanner
