import { useMemo, type FC } from "react"
import { useAccount, useBalance } from "wagmi"
import {
  Alert,
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Link,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import { isAddress } from "@ethersproject/address"
import truncate from "truncate"
import { BigNumber } from "@ethersproject/bignumber"
import isEqual from "lodash.isequal"

import type { Account, Delegate, Token } from "query/graphql"
import {
  useAddressHeaderQuery,
  useOrganizationMyVotingPowerMultiChainQuery,
  useOrganizationMyVotingPowerQuery,
  type Organization,
} from "query/graphql"
import CardContainer from "common/components/CardContainer"
import { addressToAccountId } from "web3/helpers/transformers"
import { getMainnetReference } from "web3/helpers/chainReference"
import UserAvatar from "common/components/UserAvatar"
import { pluralize, shortString, subString } from "common/helpers/string"
import CopyButton from "common/components/CopyButton"
import DelegateButton from "delegation/components/DelegateButton"
import { ROUTES } from "common/constants/routes"
import { getWeightLabel } from "common/helpers/bignumber"
import { isSameAddress } from "web3/helpers/address"
import { UserGroup } from "ui/components/icons/font-awesome/UserGroup"
import WarningTriangleIcon from "ui/components/icons/WarningTriangleIcon"
import { isMultiChainTokenIds } from "organization/helpers/organization"
import MobileCountBox from "common/components/MobileCountBox"
import { useDevice } from "common/hooks/useDevice"
import { useMe } from "user/providers/MeProvider"
import type { TokenWrapperSettings } from "token/constants/token-wrapper"
import { getTokenWrapperSettings } from "token/constants/token-wrapper"
import { getChainIdParams } from "web3/helpers/chainId"
import { useAddress } from "web3/hooks/useAddress"
import { labelNumber } from "common/helpers/number"

type OrganizationMyVotingPowerProps = {
  organization: Organization
}
const OrganizationMyVotingPower: FC<OrganizationMyVotingPowerProps> = ({
  organization,
}) => {
  const { onLargeDevice, onLittleDevice } = useDevice()
  const isMultiChain = isMultiChainTokenIds(organization.tokenIds)
  const tokenId = organization.tokenIds?.[0]
  const { address: walletAddress } = useAccount()
  const me = useMe()

  const address = me?.type === "SAFE" ? me.address : walletAddress

  const { data } = useAddressHeaderQuery(
    {
      accountId: addressToAccountId(
        address ? (address as `0x${string}`) : "",
        getMainnetReference(),
      ),
    },
    {
      enabled: Boolean(address),
    },
  )

  const { data: delegateData } = useOrganizationMyVotingPowerQuery(
    {
      delegateeInput: {
        address: address as string,
        tokenId,
      },
      delegateInput: {
        address: address as string,
        organizationId: organization.id,
      },
      tokenBalancesInput: {
        address: address as string,
        organizationID: organization?.id,
        tokenId,
      },
    },
    {
      enabled: Boolean(address) && Boolean(tokenId) && !isMultiChain,
    },
  )

  const { data: delegateMultiChainData } =
    useOrganizationMyVotingPowerMultiChainQuery(
      {
        input: {
          filters: {
            organizationId: organization?.id,
            address: address as string,
          },
        },
      },
      { enabled: Boolean(address) && isMultiChain },
    )

  const account = data?.account ?? undefined

  const tokenBalance = delegateData?.tokenBalances?.find((tokenBalance) =>
    isEqual(tokenBalance.token.id.toLowerCase(), tokenId.toLowerCase()),
  )

  // Calculate voting power content based on regular or MultiChain DAO
  const votingPowerContent = useMemo(() => {
    if (delegateData?.delegate && !isMultiChain) {
      return {
        delegatorsCount: delegateData?.delegate?.delegatorsCount,
        token: delegateData?.delegate?.token as Token,
        votes: delegateData?.delegate?.votesCount,
      }
    }

    if (
      delegateMultiChainData?.delegates?.nodes &&
      delegateMultiChainData?.delegates?.nodes.length > 0 &&
      isMultiChain
    ) {
      const delegate = delegateMultiChainData.delegates.nodes[0] as Delegate

      return {
        delegatorsCount: delegate.delegatorsCount,
        token: delegate.token as Token,
        votes: delegate.votesCount,
      }
    }

    return {
      delegatorsCount: undefined,
      token: undefined,
      votes: undefined,
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delegateData, delegateMultiChainData])

  if (!tokenId) return null

  if (!account) {
    return (
      <>
        <CardContainer display={onLargeDevice} title="My voting power">
          <Stack pt={0} spacing={4}>
            <Text color="gray.600" fontSize="md">
              Connect your wallet to see your voting power and start delegating
            </Text>

            {/* TODO(@nicolas): Replace with useModals hook to open Connect Wallet - I couldn't integreate successfully */}
            <Link
              href={ROUTES.user.connectWallet(
                ROUTES.governance.id(organization.slug),
              )}
            >
              <Button fontSize="md" variant="primary" width="100%">
                Connect wallet
              </Button>
            </Link>
          </Stack>
        </CardContainer>
      </>
    )
  }

  const tokenWrapperSettings = getTokenWrapperSettings(organization.id)

  return (
    <>
      <CardContainer display={onLargeDevice} title="My voting power">
        <Stack pt={{ base: 4, md: 0 }}>
          <AccountIdentity
            account={account as Account}
            organization={organization}
          />

          <Stack mt={2} spacing={1}>
            {votingPowerContent ? (
              <VotingPowerContent
                delegatorsCount={votingPowerContent.delegatorsCount}
                token={votingPowerContent.token}
                votes={votingPowerContent.votes}
              />
            ) : null}

            {delegateData?.delegatee && !isMultiChain ? (
              <DelegatingToContent
                delegateTo={delegateData.delegatee.delegate as Account}
                organization={organization}
                token={delegateData.delegatee.token as Token}
                votes={delegateData.delegatee.votes}
              />
            ) : null}

            {/* TODO(@nicolas): Pending to support this component on MultiChain */}
            {!delegateData?.delegatee &&
            tokenBalance?.balance &&
            tokenBalance?.token &&
            !isMultiChain ? (
              <ActiveVotingPowerWarning
                balance={tokenBalance?.balance}
                token={tokenBalance?.token as Token}
              />
            ) : null}
          </Stack>

          <Stack mt={2}>
            <DelegateButton organization={organization} variant="primary">
              {delegateData?.delegatee ? "Update delegation" : "Delegate"}
            </DelegateButton>
          </Stack>

          {tokenWrapperSettings ? (
            <TokenWrapperContent
              organization={organization}
              tokenWrapperSettings={tokenWrapperSettings}
            />
          ) : null}

          {account ? (
            <Link
              href={ROUTES.governance.myVotingPower.index(organization.slug)}
            >
              <Button variant="secondary" width="100%">
                View details
              </Button>
            </Link>
          ) : null}
        </Stack>
      </CardContainer>

      <Box display={onLittleDevice}>
        <MobileCountBox
          footer={
            votingPowerContent?.delegatorsCount
              ? `${votingPowerContent.delegatorsCount} incoming ${pluralize(
                  votingPowerContent.delegatorsCount,
                  "delegation",
                  "delegations",
                )}`
              : "Incoming delegations: -"
          }
          h="110px"
          title="My voting power"
          url={
            address
              ? ROUTES.governance.delegate.profile(organization.slug, address)
              : undefined
          }
        >
          <VotingPowerContentMobile
            token={votingPowerContent.token as Token}
            votes={votingPowerContent.votes}
          />
        </MobileCountBox>
      </Box>
    </>
  )
}

type AccountIdentityProps = {
  account: Account
  organization: Organization
}
const AccountIdentity: FC<AccountIdentityProps> = ({
  account,
  organization,
}) => {
  const { address, picture, name } = account

  const displayName = Boolean(name) ? name : address

  return (
    <Stack align="center" direction="row" spacing={3}>
      <Link
        href={ROUTES.governance.delegate.profile(organization.slug, address)}
      >
        <UserAvatar
          address={address}
          shouldShowBadge={false}
          size="md"
          src={picture}
        />
      </Link>
      <Flex direction="column">
        <Link
          href={ROUTES.governance.delegate.profile(organization.slug, address)}
        >
          <Text color="gray.800" fontSize="md" fontWeight="bold">
            {isAddress(displayName)
              ? shortString(displayName)
              : truncate(displayName, 20)}
          </Text>
        </Link>
        <Stack align="center" direction="row" mt="-4px">
          <Text color="gray.600" fontSize="sm" fontWeight="medium">
            {shortString(address)}
          </Text>
          <CopyButton size={8} tooltipLabel="address" value={address} />
        </Stack>
      </Flex>
    </Stack>
  )
}

type DelegatingToContentProps = {
  votes: string
  token: Token
  delegateTo?: Account
  organization: Organization
}
const DelegatingToContent: FC<DelegatingToContentProps> = ({
  votes,
  token,
  delegateTo,
  organization,
}) => {
  const address = useAddress()

  const { decimals, symbol } = token

  const votesLabel =
    votes && typeof decimals !== "undefined" && decimals >= 0
      ? getWeightLabel(BigNumber.from(votes), decimals)
      : ""

  const delegatingToBox = () => {
    if (!delegateTo) {
      return null
    }

    if (isSameAddress(delegateTo.address, address)) {
      return (
        <Text as="span" color="gray.600" fontWeight="bold">
          self
        </Text>
      )
    }

    const delegateLabel = !!delegateTo.name
      ? delegateTo.name
      : delegateTo.address

    return (
      <Stack align="center" as="span" direction="row" ml={2} spacing={2}>
        <UserAvatar
          address={delegateTo.address}
          size="xs"
          src={delegateTo.picture}
        />
        <Link
          isExternal
          href={ROUTES.governance.delegate.profile(
            organization.slug,
            delegateTo.address,
          )}
        >
          <Text color="gray.600" fontWeight="bold">
            {isAddress(delegateLabel)
              ? shortString(delegateLabel)
              : truncate(delegateLabel, 25)}
          </Text>
        </Link>
      </Stack>
    )
  }

  return (
    <Box as="span" color="gray.600" fontSize="sm" fontWeight="regular">
      Delegating
      <Text as="span" fontWeight="semibold" mx={1}>
        {votesLabel} {symbol}
      </Text>
      to {delegatingToBox()}
    </Box>
  )
}

type ActiveVotingPowerWarningProps = {
  balance: string
  token: Token
}
const ActiveVotingPowerWarning: FC<ActiveVotingPowerWarningProps> = ({
  balance,
  token,
}) => {
  const { decimals, symbol } = token

  const balanceLabel =
    balance && typeof decimals !== "undefined" && decimals >= 0
      ? getWeightLabel(BigNumber.from(balance), decimals)
      : ""

  return (
    <Stack>
      <Flex color="gray.600" fontSize="sm" fontWeight="regular">
        <Text as="span" fontWeight="semibold" mr={1}>
          {balanceLabel} {symbol}
        </Text>{" "}
        not delegated
      </Flex>

      {balanceLabel !== "0" ? (
        <Alert backgroundColor="yellow.50" status="warning">
          <HStack align="top">
            <Icon
              as={WarningTriangleIcon}
              color="yellow.500"
              h={5}
              mt={0.5}
              w={5}
            />
            <Text color="yellow.700" textStyle="sm">
              Delegate your vote to active your voting power
            </Text>
          </HStack>
        </Alert>
      ) : null}
    </Stack>
  )
}

type VotingPowerContentMobileProps = {
  votes?: string
  token?: Token
}
const VotingPowerContentMobile: FC<VotingPowerContentMobileProps> = ({
  votes,
  token,
}) => {
  if (!votes) {
    return <>-</>
  }

  if (!token) {
    return null
  }

  const { decimals, symbol } = token

  const votesLabel =
    votes && typeof decimals !== "undefined" && decimals >= 0
      ? getWeightLabel(BigNumber.from(votes), decimals)
      : ""

  const hasVotingPower = votesLabel !== "0"
  const symbolLabel = subString(symbol, 4, "...")

  return (
    <Text maxWidth="100%">
      {hasVotingPower ? `${votesLabel} ${symbolLabel}` : "-"}
    </Text>
  )
}

type VotingPowerContentProps = {
  delegatorsCount?: number
  votes?: string
  token?: Token
}
const VotingPowerContent: FC<VotingPowerContentProps> = ({
  delegatorsCount,
  votes,
  token,
}) => {
  if (!votes) {
    return (
      <Text color="gray.600" fontSize="sm" fontWeight="regular">
        Total voting power:{" "}
        <Text as="span" fontWeight="semibold">
          -
        </Text>
      </Text>
    )
  }

  if (!token) {
    return null
  }

  const { decimals, symbol } = token

  const votesLabel =
    votes && typeof decimals !== "undefined" && decimals >= 0
      ? getWeightLabel(BigNumber.from(votes), decimals)
      : ""

  const hasVotingPower = votesLabel !== "0"

  return (
    <Stack align="center" direction="row">
      <Text color="gray.600" fontSize="sm" fontWeight="regular">
        Total voting power:{" "}
        <Text as="span" data-qa="total-voting-power-1" fontWeight="semibold">
          {hasVotingPower ? `${votesLabel} ${symbol}` : "-"}
        </Text>
      </Text>
      {hasVotingPower ? (
        <Tooltip
          label={`${delegatorsCount} incoming ${pluralize(
            delegatorsCount ?? 0,
            "delegation",
            "delegations",
          )}`}
        >
          <Badge borderRadius="md" mx={1} px={2} py={1}>
            <Flex align="center">
              <Text
                align="center"
                color="gray.500"
                fontWeight="bold"
                textStyle="sm"
              >
                {delegatorsCount}
              </Text>
              <Icon as={UserGroup} boxSize={3} color="gray.600" ml={1} />
            </Flex>
          </Badge>
        </Tooltip>
      ) : null}
    </Stack>
  )
}

type TokenWrapperContentProps = {
  organization: Organization
  tokenWrapperSettings: TokenWrapperSettings
}
const TokenWrapperContent: FC<TokenWrapperContentProps> = ({
  organization,
  tokenWrapperSettings,
}) => {
  const address = useAddress()

  const { underlyingToken } = tokenWrapperSettings
  const { reference } = getChainIdParams(underlyingToken.chainId)

  const { data: balanceData } = useBalance({
    address: address as `0x${string}`,
    chainId: reference ?? undefined,
    token: underlyingToken.contractAddress as `0x${string}`,
    enabled: Boolean(address),
  })

  const balanceFormattedNumber = balanceData?.formatted
    ? Number(balanceData.formatted)
    : 0

  if (!balanceFormattedNumber || balanceFormattedNumber === 0) return null

  const balanceLabel =
    balanceFormattedNumber > 0 ? labelNumber(balanceFormattedNumber) : "0"

  return (
    <Stack direction="column" mt={2}>
      <Flex color="gray.600" fontSize="sm" fontWeight="regular">
        <Text as="span" fontWeight="semibold" mr={1}>
          {balanceLabel} {underlyingToken.symbol}
        </Text>{" "}
        not wrapped
      </Flex>

      <Alert backgroundColor="yellow.50" status="warning">
        <HStack align="top">
          <Icon
            as={WarningTriangleIcon}
            color="yellow.500"
            h={5}
            mt={0.5}
            w={5}
          />
          <Text color="yellow.700" textStyle="sm">
            {underlyingToken.symbol} must be wrapped before participating in
            governance
          </Text>
        </HStack>
      </Alert>

      <Link href={ROUTES.governance.token.wrap(organization.slug)}>
        <Button variant="primary" width="100%">
          Wrap tokens
        </Button>
      </Link>
    </Stack>
  )
}

export default OrganizationMyVotingPower
