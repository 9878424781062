import { useMemo, type FC } from "react"
import { Badge, Button, Flex, HStack, Stack, Text } from "@chakra-ui/react"

import CardContainer from "common/components/CardContainer"
import MarkdownViewer from "common/components/MarkdownViewer"
import { OrganizationStatus } from "organization/helpers/fetcher"
import { useMediaQuery } from "common/hooks/useMediaQuery"

type OrganizationGovernancePortalProps = {
  content: string
  status: OrganizationStatus
}

const OrganizationGovernancePortal: FC<OrganizationGovernancePortalProps> = ({
  content,
  status,
}) => {
  const { isMobile } = useMediaQuery()

  const title = useMemo(() => {
    if (isMobile) {
      return (
        <HStack>
          <Text>Governance portal</Text>
          <Badge>Soon</Badge>
        </HStack>
      )
    }

    return "Governance portal"
  }, [isMobile])

  return (
    <CardContainer
      displayCollapseButton={isMobile}
      elementTitle={title}
      isCollapsed={
        isMobile &&
        (status === OrganizationStatus.CallForDelegates ||
          status === OrganizationStatus.ClaimLive)
      }
      mt={0}
    >
      <Stack pt={{ base: 2, lg: 0 }} spacing={4}>
        <MarkdownViewer content={content} />

        {status === OrganizationStatus.CallForDelegates ||
        status === OrganizationStatus.ClaimLive ? (
          <Flex>
            <Button
              isDisabled
              fontSize="md"
              variant="primary"
              width={{ base: "100%", md: "auto" }}
            >
              Coming soon
            </Button>
          </Flex>
        ) : null}
      </Stack>
    </CardContainer>
  )
}

export default OrganizationGovernancePortal
