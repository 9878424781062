import type { StackProps } from "@chakra-ui/react"
import { Spacer, Stack, Text, Button } from "@chakra-ui/react"
import type { FC, ReactNode } from "react"

import Link from "common/components/Link"

export type ProductAnnouncementButton = {
  label: string
  url: string
  isPrimary?: boolean
  isExternal?: boolean
}

type Props = {
  title?: string
  description?: string | ReactNode
  buttons?: ProductAnnouncementButton[]
  children?: ReactNode
}
const ProductAnnouncementBanner: FC<Props & StackProps> = ({
  title,
  description,
  children,
  buttons = [],
  ...stackProps
}) => {
  const displayButtons = buttons ? buttons.length > 0 : false

  return (
    <Stack
      align={{ base: "left", sm: "center" }}
      bg="linear-gradient(90deg, #725BFF 5.94%, #6E40CF 108.29%)"
      borderRadius="md"
      direction={{ base: "column", sm: "row" }}
      mb={2}
      px={4}
      py={4}
      width="100%"
      {...stackProps}
    >
      {title ? (
        <Text as="h3" color="white" fontWeight="bold" textStyle="lg">
          {title}
        </Text>
      ) : null}
      {description ? (
        <Text color="white" textStyle="md">
          {description}
        </Text>
      ) : null}
      {children ? (
        <Text
          as="h3"
          color="white"
          fontWeight="bold"
          textStyle="lg"
          width="100%"
        >
          {children}
        </Text>
      ) : null}
      <Spacer />
      {displayButtons && buttons ? (
        <Stack direction={{ base: "column", sm: "row" }}>
          {buttons.map((button, idx) => {
            const { label, url, isExternal, isPrimary } = button

            return (
              <Link
                key={`button-${idx}`}
                href={url}
                isExternal={isExternal}
                width={{ base: "100%", sm: "auto" }}
              >
                <Button
                  _hover={
                    isPrimary
                      ? { bg: "gray.100" }
                      : { bg: "transparent", textDecoration: "underline" }
                  }
                  bgColor={isPrimary ? "white" : undefined}
                  borderColor={isPrimary ? "white" : undefined}
                  color={isPrimary ? "purple.600" : "white"}
                  variant={isPrimary ? "primary" : "tertiary"}
                  width="100%"
                >
                  {label}
                </Button>
              </Link>
            )
          })}
        </Stack>
      ) : null}
    </Stack>
  )
}

export default ProductAnnouncementBanner
