import type { FC } from "react"
import React from "react"
import { Flex, Text, Tooltip } from "@chakra-ui/react"

import { DATE_FORMATS } from "common/constants/date"
import { getDateFromTimestamp, getTimestampFromIetf } from "common/helpers/date"

type TallyProposalCreatedDateProps = {
  createdAt: string
}

const TallyProposalCreatedDate: FC<TallyProposalCreatedDateProps> = ({
  createdAt,
}) => {
  const timestamp = getTimestampFromIetf(createdAt || "")
  const date = getDateFromTimestamp(timestamp)
  const createdAtLabel = date.format(DATE_FORMATS.statusHistory)
  const createdAtFullYearLabel = date.format(DATE_FORMATS.statusHistoryFullYear)

  return (
    <Flex align="flex-end" direction="column">
      <Tooltip bg="gray.700" label={createdAtFullYearLabel}>
        <Text align="right" color="gray.500" textStyle="body.regular.md">
          {createdAtLabel}
        </Text>
      </Tooltip>
    </Flex>
  )
}

export default TallyProposalCreatedDate
