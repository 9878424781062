import type { FC } from "react"
import React, { useMemo } from "react"
import type { TagProps } from "@chakra-ui/react"
import { HStack, Tag } from "@chakra-ui/react"

import type { Proposal } from "query/graphql"
import { ProposalStatus } from "query/graphql"

type Props = {
  tallyProposal: Proposal
  versioning?: boolean
}

const TallyProposalStatusTag: FC<Props & TagProps> = ({
  tallyProposal,
  versioning = false,
  ...tagProps
}) => {
  const shouldDisplayOnchainTag = Boolean(tallyProposal.executableCalls)

  const { bg, color } = useMemo(() => {
    switch (tallyProposal.status) {
      case ProposalStatus.Draft:
        return {
          bg: "gray.100",
          color: "gray.500",
        }
      case ProposalStatus.Submitted:
        return {
          bg: "purple.100",
          color: "purple.500",
        }
      default:
        return {
          bg: "gray.100",
          color: "gray.500",
        }
    }
  }, [tallyProposal.status])

  if (versioning) {
    return (
      <Tag bg="yellow.200" textColor="yellow.800">
        Version history preview
      </Tag>
    )
  }

  if (!tallyProposal.status) return null

  return (
    <HStack data-qa="proposals-status">
      <Tag bg={bg} color={color} {...tagProps}>
        {tallyProposal.status}
      </Tag>
      {shouldDisplayOnchainTag ? (
        <Tag bg="gray.100" textColor="gray.500">
          Onchain
        </Tag>
      ) : null}
    </HStack>
  )
}

export default TallyProposalStatusTag
